import React, { lazy } from 'react'
const EbillItemSettingsForm = lazy(() => import('./EbillItemSettingsForm'))

const EbillItemSettings = lazy(() => import('./EbillItemSettings'))

const BillItemSettingsRoutes = [
  {
    path: 'bill-item-settings',
    children: [
      {
        path: '',
        element: <EbillItemSettings />,
      },
      {
        path: 'edit/:id',
        element: <EbillItemSettingsForm />,
      },
    ],
  },
]

export default BillItemSettingsRoutes
