import {
  Chip,
  Container,
  EmptyState,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  RowFlex,
  SearchBar,
  SubContainerX,
  useMenuOption,
} from '@ifca-ui/core'
import { Add, MoreVert } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { Dialog } from 'components/Dialogs/Dialog'
import TextX from 'components/General/TextX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useActivateTaxSchemeMutation,
  useDeactivateTaxSchemeMutation,
  useGetTaxSchemeListQuery,
} from 'generated/graphql'
import { CommonSettingBase } from 'helpers/CommonRoutes'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import React, { useContext, useEffect } from 'react'

const TaxSchemeListing = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  const [search, setSearchInput] = React.useState('')
  const [isDeletable, setIsDeletable] = React.useState(false)
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { navBack, navTo, navData, iNavTo } = useNav()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)

  //--Start GraphQL--
  const {
    loading: getTaxSchemeListLoading,
    refetch,
    data: { getTaxSchemeList } = {
      getTaxSchemeList: [],
    },
  } = useGetTaxSchemeListQuery({
    fetchPolicy: 'network-only',
  })

  //deactivate
  const [deactivateTaxScheme, { loading: deactivateTaxSchemeLoading }] =
    useDeactivateTaxSchemeMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: () => {
        setSnackBar(
          isDeletable == true
            ? SystemMsgs.deleteRecord()
            : SystemMsgs.deactiveRecord()
        )
        setDeleteDialog(false)
        refetch()
      },
    })

  //activate
  const [activateTaxScheme, { loading: activateTaxSchemeLoading }] =
    useActivateTaxSchemeMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: () => {
        setSnackBar(SystemMsgs.activeRecord())
        setActivationDialog(false)
        refetch()
      },
    })
  //--End GraphQL--

  var deactivateTaxSchemeItem = () => {
    setDeleteDialog(false)
    deactivateTaxScheme({
      variables: {
        taxSchemeId: menu.id,
        isDeletable: isDeletable,
      },
    })
  }

  var activateTaxSchemeItem = () => {
    setActivationDialog(false)
    activateTaxScheme({
      variables: {
        taxSchemeId: menu.id,
      },
    })
  }

  //#region delete dialog
  const [deleteDialog, setDeleteDialog] = React.useState(false)
  const DeleteDialog = (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={deleteDialog}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'Tax Scheme',
              },
            },
            rightTopTitle: {
              text: isDeletable ? 'Delete' : 'Deactivate',
            },
          },
        },
        bodyText: isDeletable
          ? 'Are you sure want to delete?'
          : 'Are you sure want to deactivate?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              deactivateTaxSchemeItem()
            },
          },
        ],
      }}
    />
  )
  //#endregion

  //#region activate dialog
  const [activationDialog, setActivationDialog] = React.useState(false)
  const ActivationDialog = (
    <Dialog
      useDefaultDialogHeader={true}
      useDefaultDialogBody={true}
      open={activationDialog}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: 'Bank',
              },
            },
            rightTopTitle: {
              text: 'Activate',
            },
          },
        },
        bodyText: 'Are you sure want to activate?',
      }}
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setActivationDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            type: 'button',
            onClick: () => {
              activateTaxSchemeItem()
            },
          },
        ],
      }}
    />
  )
  //#endregion

  useEffect(() => {
    setOriginalListing(getTaxSchemeList)
  }, [getTaxSchemeList])

  useEffect(() => {
    if (navData?.['searchInput']) {
      setSearchInput(navData?.['searchInput'])
      searchValue(navData?.['searchInput'])
    }
  }, [navData])

  //#region search tax setup
  const searchValue = x => {
    handleSearch(x, ['name'])
  }
  //#endregion

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${rootState.profileData?.subscriptionName}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Tax Scheme',
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  const isActive = menu?.data?.commonStatus === 'ACTIVE'
  const isInTransactionData = menu?.data?.isExistInTransactionData?.length > 0
  const isInMasterData = menu?.data?.isExistInMasterData?.length > 0

  return (
    <>
      {DeleteDialog}
      {ActivationDialog}
      <SubContainerX>
        <SearchBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              {`Tax Scheme Listing`} (
              <Typography
                component="span"
                variant="inherit"
                className="search-bar-count-f"
              >
                {`${getTaxSchemeList?.length ?? 0}`}
              </Typography>
              )
            </Typography>
          }
          searchInput={{
            value: search,
            onChange: e => {
              setSearchInput(e.target.value)
              searchValue(e.target.value)
            },
            onChangeClearInput: () => {
              searchValue('')
              setSearchInput('')
            },
          }}
        />
      </SubContainerX>
      <Container>
        <List>
          {getTaxSchemeList === undefined || getTaxSchemeList?.length === 0 ? (
            <EmptyState subTitle="Click add button to insert new records" />
          ) : (
            filteredList?.map((x, i) => {
              return (
                <ListItem
                  key={x.id}
                  className={
                    x?.commonStatus == 'INACTIVE' ? 'inactive-list' : ''
                  }
                  secondaryAction={
                    <IconButton onClick={e => handleClick(e, x.id, i, x)}>
                      <MoreVert />
                    </IconButton>
                  }
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <>
                        <RowFlex className="text-xsTitle">
                          <TextX data={x.name} />
                          {!x.isExclusive ? (
                            <Chip
                              label={
                                <TextX
                                  className="text-label"
                                  variant="inherit"
                                  data={'IsInclusive'}
                                />
                              }
                              sx={{
                                marginLeft: '5px',
                                background: '#ffe7cb',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </RowFlex>
                      </>
                    }
                    secondary={
                      <RowFlex>
                        <TextX
                          data={x.description ?? '-'}
                          className="text-desc"
                          color="#454545"
                        />
                      </RowFlex>
                    }
                  />
                </ListItem>
              )
            })
          )}
        </List>
        <FloatButton
          color="primary"
          onClick={() => {
            iNavTo({
              routeUrl: `${CommonSettingBase}/tax-scheme/add`,
              leftState: { searchInput: search },
            })
          }}
        >
          <Add />
        </FloatButton>
      </Container>

      <Menu
        id="default-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            iNavTo({
              routeUrl: `${CommonSettingBase}/tax-scheme/${menu?.id}`,
              leftState: { searchInput: search },
            })
          }}
        >
          View
        </MenuItem>

        {menu?.data?.commonStatus == 'ACTIVE' && (
          <MenuItem
            onClick={() => {
              iNavTo({
                routeUrl: `${CommonSettingBase}/tax-scheme/${menu?.id}/edit`,
                leftState: { searchInput: search },
              })
            }}
          >
            Edit
          </MenuItem>
        )}

        {isActive && isInTransactionData && (
          <MenuItem
            onClick={() => {
              if (isInMasterData) {
                setSnackBar(
                  `Failed to  deactivate, tax is in used in ${menu?.data?.isExistInMasterData}`
                )
              } else {
                setIsDeletable(false)
                setDeleteDialog(true)
                handleClose()
              }
            }}
          >
            Deactivate
          </MenuItem>
        )}

        {isActive && !isInTransactionData && (
          <MenuItem
            onClick={() => {
              if (isInMasterData) {
                setSnackBar(
                  `Failed to delete, tax is in used in ${menu?.data?.isExistInMasterData} `
                )
              } else {
                setIsDeletable(true)
                setDeleteDialog(true)
                handleClose()
              }
            }}
          >
            Delete
          </MenuItem>
        )}

        {menu?.data?.commonStatus == 'INACTIVE' && (
          <MenuItem
            onClick={() => {
              setActivationDialog(true)
              handleClose()
            }}
          >
            Activate
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default TaxSchemeListing
