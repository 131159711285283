import { Tooltip, TooltipProps } from '@mui/material'
import React from 'react'

interface Props extends TooltipProps {
  title: string
  children: React.ReactElement
}

const TooltipX: React.FC<Props> = ({ title, children, ...props }) => {
  return (
    <Tooltip title={title} {...props}>
      <div
        style={{
          display: 'flex',
          width: 'fit-content',
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

export default TooltipX
