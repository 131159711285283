import { useCallback, useEffect, useState } from 'react'

interface UseCheckboxGroupProps {
  initialValues: string[]
}

export const useCheckboxGroup = ({ initialValues }: UseCheckboxGroupProps) => {
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    () => {
      return initialValues.reduce(
        (acc, id) => ({
          ...acc,
          [id]: false,
        }),
        {}
      )
    }
  )
  const [totalCount, setTotalCount] = useState(initialValues?.length || 0)
  const checkedIds = Object.entries(checkedItems)
    .filter(([_, value]) => value === true)
    .map(([id]) => id)
  const checkedCount = checkedIds.length
  const parentChecked = checkedCount === totalCount && totalCount > 0
  const parentIndeterminate = checkedCount > 0 && checkedCount < totalCount

  useEffect(() => {
    const checkedItemsCount = Object.keys(checkedItems).length
    if (checkedItemsCount > 0) {
      setTotalCount(checkedItemsCount)
    }
  }, [checkedItems])

  const toggleParent = useCallback(
    (checked: boolean) => {
      setCheckedItems(prev =>
        Object.keys(prev).reduce((acc, v) => {
          acc[v] = checked
          return acc
        }, {})
      )
    },
    [checkedItems]
  )
  const toggleChild = useCallback((id: string) => {
    setCheckedItems(prev => ({
      ...prev,
      [id]: !prev[id],
    }))
  }, [])

  /**
   * Set checked items via passing the ids.
   *
   * @param {string[]} idList - List of ids to be initialized as.
   */
  const resetCheckedItems = (idList: string[]) => {
    setCheckedItems(
      idList.reduce((prev, curr) => ({ ...prev, [curr]: false }), {})
    )
  }

  return {
    checkedIds,
    checkedItems,
    parentChecked,
    parentIndeterminate,
    toggleParent,
    toggleChild,
    setCheckedItems,
    resetCheckedItems,
  }
}
