import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

/**
 * @example
 * ```tsx
 * useHeader({
 *   backPath: '/projects',
 *   current: 'Project Details',
 *   maxItems: 2,
 *   rightText: 'Edit',
 *   title: 'projectName',
 *   icon: 'close',
 * })
 * ```
 */

interface Props {
  backPath?: string
  current: string
  maxItems: number
  rightText?: string
  title?: 'subscriptionName' | 'projectName' | string
  icon?: string
  rightIcon?: string
  rightProps?: any
}

const useHeader = ({
  backPath = '', // Default value for optional backPath
  current,
  maxItems,
  rightText = '', // Default value for optional rightText
  title,
  icon = 'back',
  rightIcon = '',
  rightProps,
}: Props) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { navBack } = useNav()
  const navigate = useNavigate()

  // Memoize title to avoid unnecessary re-calculation
  const computedProjectName = useMemo(() => {
    switch (title) {
      case 'subscriptionName':
        return rootState.profileData.subscriptionName || ''
      case 'projectName':
        return rootState.projectNama || ''
      default:
        return title
    }
  }, [title, rootState.projectNama])

  const getLeftIconProps = () => {
    if (icon === 'none') return undefined

    let props = {}

    if (icon === 'menu') {
      props = {
        onClick: () =>
          rootDispatch({
            type: 'isDrawerOpen',
            payload: !rootState.isDrawerOpen,
          }),
      }
    } else {
      props = {
        onClick: () => {
          backPath ? navigate(backPath) : navBack()
        },
      }
    }

    return {
      icon: icon,
      props: props,
    }
  }

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: getLeftIconProps(),
        rightIcon: {
          icon: rightIcon,
          // Add functionality if required for the rightIcon
          props: rightProps,
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: computedProjectName ?? '',
        },
        bottomSection: {
          breadcrumbs: {
            current,
            maxItems,
          },
          rightText,
        },
      },
    })
    // Ensure dependencies are correctly specified
  }, [computedProjectName])
}

export default useHeader
