import { ColumnFlex, RowFlex, SubContainerX, Subheader } from '@ifca-ui/core'
import { PxLetterB } from '@ifca-ui/icons/dist/index.esm'
import { Functions, MonetizationOn } from '@mui/icons-material'
import { amtStr } from 'helpers/numFormatter'
import TextX from './TextX'
import TooltipX from './TooltipX'

interface SalesContractSubheaderProps {
  getSalesContractById: any
  billedSummary?: { totalBilled: number; totalOutstanding: number }
}

export const SalesContractSubheader = ({
  getSalesContractById,
  billedSummary,
}: SalesContractSubheaderProps) => {
  return (
    <>
      <SubContainerX>
        <Subheader>
          <RowFlex
            className="text-icon text-desc"
            fullWidth
            mainAxisAlignment="space-between"
          >
            <ColumnFlex gap={'2px'}>
              <TooltipX title="Unit No." placement="right">
                <RowFlex gap={'2px'} className="text-xsTitle">
                  <TextX data={getSalesContractById?.unit?.unitNo} />
                  <div>
                    {getSalesContractById?.unit?.isBumi && <PxLetterB />}
                  </div>
                </RowFlex>
              </TooltipX>
              <TooltipX
                title={
                  !!getSalesContractById?.isCompany
                    ? 'Company Name'
                    : 'Purchaser Name'
                }
                placement="right"
              >
                <TextX
                  data={
                    !!getSalesContractById?.isCompany
                      ? `${getSalesContractById?.contact?.companyName} (${getSalesContractById?.contact?.fullName})`
                      : getSalesContractById?.contact?.fullName
                  }
                  color={'#757575'}
                />
              </TooltipX>
            </ColumnFlex>
            <ColumnFlex gap={'2px'}>
              <TooltipX title="Book Date" placement="right">
                <TextX
                  color={'#757575'}
                  data={getSalesContractById?.bookDate}
                  dataType="Date"
                />
              </TooltipX>
              <TooltipX
                title={
                  getSalesContractById?.spaDate
                    ? 'SPA Date'
                    : 'Expected Signed Date'
                }
                placement="right"
              >
                <TextX
                  color={getSalesContractById?.spaDate ? '#757575' : 'red'}
                  data={
                    getSalesContractById?.spaDate ??
                    getSalesContractById?.expectedSnpDate
                  }
                  dataType="Date"
                  alt="-"
                />
              </TooltipX>
            </ColumnFlex>
            {billedSummary ? (
              <ColumnFlex gap={'2px'}>
                <TooltipX title="Total Billed" placement="left">
                  <RowFlex gap={'2px'} className="c-orange">
                    <MonetizationOn />
                    <div> {amtStr(billedSummary.totalBilled ?? 0)}</div>
                  </RowFlex>
                </TooltipX>

                <TooltipX title="Total Outstanding" placement="left">
                  <RowFlex gap={'2px'} className="c-orange">
                    <Functions />
                    <div>{amtStr(billedSummary.totalOutstanding ?? 0)}</div>
                  </RowFlex>
                </TooltipX>
              </ColumnFlex>
            ) : (
              <ColumnFlex gap={'2px'}>
                <TooltipX title="SPA Price" placement="left">
                  <RowFlex gap={'2px'} className="c-orange">
                    <MonetizationOn />
                    <div> {amtStr(getSalesContractById?.sellingPrice)}</div>
                  </RowFlex>
                </TooltipX>

                <TooltipX title="Total Selling Price" placement="left">
                  <RowFlex gap={'2px'} className="c-orange">
                    <Functions />
                    <div>
                      {amtStr(
                        getSalesContractById?.saleExtension?.totalSalesValue ??
                          getSalesContractById?.sellingPrice
                      )}
                    </div>
                  </RowFlex>
                </TooltipX>
              </ColumnFlex>
            )}
          </RowFlex>
        </Subheader>
      </SubContainerX>
    </>
  )
}
