import { ColumnFlex, RowGrid } from '@ifca-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import { useAddressAutoCompleteNew } from 'helpers/Hooks/useAddressAutoCompleteNew'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { disableLabelAutocomplete } from '../../../helpers/StringNumberFunction/formatLabel'

interface AddressState {
  address: string
  country: { name: string }
  state: { name: string }
  city: { name: string }
  postcode: { postcode: string }
}
interface hideFieldProps {
  address?: boolean
  country?: boolean
  state?: boolean
  city?: boolean
  postcode?: boolean
}

interface disableFieldProps {
  address?: boolean
  country?: boolean
  state?: boolean
  city?: boolean
  postcode?: boolean
}

interface props {
  editDataAddress: any
  isSubmitted: boolean
  setAddressState: React.Dispatch<React.SetStateAction<AddressState>>
  isRequired: boolean
  hideField: hideFieldProps
  disableField: disableFieldProps
  renameAddress: string
}

const AddressField: React.FC<props> = ({
  editDataAddress,
  isSubmitted,
  setAddressState,
  isRequired,
  hideField,
  disableField,
  renameAddress,
}) => {
  let location = useLocation()

  const [addressText, setAddressText] = useState<string>('')
  const [editData, setEditData] = useState(location?.state)
  const {
    getCountry,
    getStatebyCountry,
    getCitybyState,
    getPostcodebyCity,
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    initializeEditValue,
    autoCompleteRef,
    state,
    reloadGoogleApi,
  } = useAddressAutoCompleteNew()

  useEffect(() => {
    setAddressState(state)
    setEditData(state)
    if (addressText != state.address) {
      setAddressText(state.address)
    }
  }, [state])

  useEffect(() => {
    initializeEditValue({
      address: editDataAddress?.address,
      country: editDataAddress?.country,
      state: editDataAddress?.state,
      city: editDataAddress?.city,
      postCode: editDataAddress?.postCode,
    })
  }, [editDataAddress])

  return (
    <>
      <ColumnFlex fullWidth>
        {!hideField.address && (
          <TextField
            label={disableLabelAutocomplete(
              isRequired ? renameAddress + ' *' : renameAddress
            )}
            variant="standard"
            margin="normal"
            multiline
            autoComplete="false"
            placeholder="Enter a location"
            inputRef={autoCompleteRef}
            onChange={e => {
              setAddressText(e.target.value)
            }}
            onFocus={e => {
              reloadGoogleApi()
            }}
            onKeyUp={e => {
              handleGoogleOnChange((e.target as HTMLInputElement).value)
            }}
            value={addressText}
            helperText={
              isRequired
                ? isSubmitted && !state.address
                  ? 'Address is required'
                  : null
                : null
            }
            error={
              isRequired ? (isSubmitted && !state.address ? true : false) : null
            }
            disabled={disableField.address}
          />
        )}

        <RowGrid
          gridTemplateColumns={
            !hideField.country && !hideField.state ? '1fr 1fr' : '1fr'
          }
          gap="5px"
        >
          {!hideField.country && (
            <Autocomplete
              options={getCountry || []}
              value={state.country ? state.country : editData?.address?.country}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleCountryOnChange(newValue)
                }
              }}
              isOptionEqualToValue={(option, newValue) =>
                option?.ID === newValue?.ID
              }
              onInputChange={(e, newValue) => {
                handleCountryOnChange({ name: newValue })
              }}
              getOptionLabel={(option: any) => option.name || ''}
              disabled={disableField.country}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label={disableLabelAutocomplete('Country')}
                    variant="standard"
                    margin="normal"
                    required={isRequired}
                    helperText={
                      isRequired
                        ? isSubmitted &&
                          !state?.country?.name &&
                          !editData?.address?.country
                          ? 'Country is required'
                          : null
                        : null
                    }
                    error={
                      isRequired
                        ? isSubmitted &&
                          !state?.country?.name &&
                          !editData?.address?.country
                          ? true
                          : false
                        : null
                    }
                  />
                )
              }}
              freeSolo
            />
          )}
          {!hideField.state && (
            <Autocomplete
              options={getStatebyCountry || []}
              value={state.state ? state.state : editData?.address.state}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleStateOnChange(newValue)
                }
              }}
              onInputChange={(e, newValue) => {
                handleStateOnChange({ name: newValue })
              }}
              isOptionEqualToValue={(option, newValue) =>
                option?.ID === newValue?.ID || option?.name === newValue?.name
              }
              getOptionLabel={(option: any) => option.name || ''}
              disabled={disableField.state}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label={disableLabelAutocomplete('State')}
                    variant="standard"
                    margin="normal"
                    required={isRequired}
                    helperText={
                      isRequired
                        ? isSubmitted &&
                          !state?.state?.name &&
                          !editData?.address?.state
                          ? 'State is required'
                          : null
                        : null
                    }
                    error={
                      isRequired
                        ? isSubmitted &&
                          !state?.state?.name &&
                          !editData?.address?.state
                          ? true
                          : false
                        : null
                    }
                  />
                )
              }}
            />
          )}
        </RowGrid>

        <RowGrid
          gridTemplateColumns={
            !hideField.city && !hideField.postcode ? '1fr 1fr' : '1fr'
          }
          gap="5px"
        >
          {!hideField.city && (
            <Autocomplete
              options={getCitybyState || []}
              value={state.city ? state.city : editData?.address?.city}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleCityOnChange(newValue)
                }
              }}
              onInputChange={(e, newValue) => {
                handleCityOnChange({ name: newValue })
              }}
              isOptionEqualToValue={(option, newValue) =>
                option?.ID === newValue?.ID || option?.name === newValue?.name
              }
              getOptionLabel={(option: any) => option.name || ''}
              disabled={disableField.city}
              renderOption={(props, option) => (
                <li {...props} key={option.ID}>
                  {option.name}
                </li>
              )}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label={disableLabelAutocomplete('City')}
                    variant="standard"
                    margin="normal"
                    required={isRequired}
                    helperText={
                      isRequired
                        ? isSubmitted &&
                          !state?.city?.name &&
                          !editData?.address?.city
                          ? 'City is required'
                          : null
                        : null
                    }
                    error={
                      isRequired
                        ? isSubmitted &&
                          !state?.city?.name &&
                          !editData?.address?.city
                          ? true
                          : false
                        : null
                    }
                  />
                )
              }}
            />
          )}

          {!hideField.postcode && (
            <Autocomplete
              options={getPostcodebyCity || []}
              value={
                state.postcode
                  ? state.postcode
                  : editData?.address?.postCode ?? editData?.postCode
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  handlePostCodeOnChange(newValue)
                }
              }}
              onInputChange={(e, newValue) => {
                handlePostCodeOnChange({ postcode: newValue })
              }}
              isOptionEqualToValue={(option, value) =>
                option?.postcode === value?.postcode
              }
              getOptionLabel={(option: any) => option.postcode || ''}
              disabled={disableField.postcode}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label={disableLabelAutocomplete(
                      isRequired ? 'Postcode *' : 'Postcode'
                    )}
                    variant="standard"
                    margin="normal"
                    helperText={
                      isRequired
                        ? isSubmitted &&
                          !state?.postcode?.postcode &&
                          !editData?.address?.postCode
                          ? 'Postcode is required'
                          : null
                        : null
                    }
                    error={
                      isRequired
                        ? isSubmitted &&
                          !state?.postcode?.postcode &&
                          !editData?.address?.postCode
                          ? true
                          : false
                        : null
                    }
                  />
                )
              }}
            />
          )}
        </RowGrid>
      </ColumnFlex>
    </>
  )
}
export default AddressField
