// SRegState.tsx
import { SalesRegistrationOutput } from 'generated/graphql'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

// Define the SRegState interface
interface SectionState {
  action?: string
  status: 'loading' | 'error' | 'success' | 'refresh'
  expanded: boolean
}

interface SummaryState {
  status: 'loading' | 'error' | 'success' | 'refresh' | 'back'
  data: SalesRegistrationOutput
}

export interface SRegState {
  summary: SummaryState
  unitInfo: SectionState
  PurchaserInfo: SectionState
  PaymentPlan: SectionState
  MarkupAmount: SectionState
  CarPark: SectionState
  Packages: SectionState
  Discount: SectionState
  SalesRebatePackages: SectionState
  SalesContractInfo: SectionState
  SolicitorInfo: SectionState
  OtherContactInfo: SectionState
  spaSigning: SectionState
  spaStamping: SectionState
  ProgressBilling: SectionState
  DigitalDocument: SectionState
}

// Define a new type that includes setSRegState
interface SRegStateContextType {
  SRegState: SRegState
  setSRegState: React.Dispatch<React.SetStateAction<SRegState>>
}

// Initialize the context with an undefined default
const SRegStateContext = createContext<SRegStateContextType | undefined>(
  undefined
)

// Create the SRegStateProvider component
export const SRegStateProvider = ({ children }: { children: ReactNode }) => {
  const [SRegState, setSRegState] = useState<SRegState>({
    summary: { status: 'loading', data: null },
    unitInfo: { status: 'loading', expanded: false },
    PurchaserInfo: { status: 'loading', expanded: false },
    PaymentPlan: { status: 'loading', expanded: false },
    MarkupAmount: { status: 'loading', expanded: false },
    CarPark: { status: 'loading', expanded: false },
    Packages: { status: 'loading', expanded: false },
    Discount: { status: 'loading', expanded: false },
    SalesRebatePackages: { status: 'loading', expanded: false },
    SalesContractInfo: { status: 'loading', expanded: false },
    SolicitorInfo: { status: 'loading', expanded: false },
    OtherContactInfo: { status: 'loading', expanded: false },
    spaSigning: { status: 'loading', expanded: false },
    spaStamping: { status: 'loading', expanded: false },
    ProgressBilling: { status: 'loading', expanded: false },
    DigitalDocument: { status: 'loading', expanded: false },
  })
  // Memoize the context value to optimize performance
  const contextValue = useMemo(() => ({ SRegState, setSRegState }), [SRegState])

  return (
    <SRegStateContext.Provider value={contextValue}>
      {children}
    </SRegStateContext.Provider>
  )
}

// Custom hook to use SRegState
export const useSRegState = () => {
  const context = useContext(SRegStateContext)
  if (context === undefined) {
    throw new Error('useSRegState must be used within an SRegStateProvider')
  }
  return context
}
