import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  ColumnFlex,
  Container,
  EmptyState,
  Footer,
  List,
  ListItem,
  Menu,
  MenuItem,
  RowFlex,
  useMenuOption,
} from '@ifca-ui/core'
import { AddBox, MoreVert } from '@mui/icons-material'
import { Box, IconButton, Switch, TextField, Typography } from '@mui/material'
import { getUserProfile } from 'UserData'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import TextX from 'components/General/TextX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  useAddTaxSchemeMutation,
  useEditTaxSchemeMutation,
  useGetTaxSchemeByIdLazyQuery,
  useGetTaxSchemeTaxQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { CommonYupValidation } from 'helpers/yup'
import { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import {
  TaxSchemeDetailProps,
  TaxSchemeDialogForm,
} from './TaxSchemeDialogForm'
import { disableLabelAutocomplete } from 'helpers/StringNumberFunction/formatLabel'

export interface TaxSchemeForm {
  name: string
  description: string
  isExclusive: boolean
  details: TaxSchemeDetailProps[]
}

const TaxSchemeForm = (props: any) => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)

  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  const { mode } = props

  const { id }: any = useParams()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)

  const [dialog, setDialog] = useState<boolean>(false)
  const [dialogMode, setDialogMode] = useState('')

  const TaxSchemeFormSchema = yup.object().shape({
    name: CommonYupValidation.requireField('Name is required').nullable(),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
  } = useForm<TaxSchemeForm>({
    defaultValues: {
      name: '',
      description: '',
      isExclusive: true,
      details: [] as any,
    },
    mode: 'onSubmit',
    resolver: yupResolver(TaxSchemeFormSchema),
  })

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'details',
  })

  //--Start GraphQL--
  const {
    loading: getTaxSchemeTaxLoading,
    data: { getTaxSchemeTax } = {
      getTaxSchemeTax: [],
    },
  } = useGetTaxSchemeTaxQuery({
    fetchPolicy: 'network-only',
  })

  const [
    getTaxScheme,
    {
      data: { getTaxSchemeById } = { getTaxSchemeById: null },
      loading: getTaxSchemeByIdLoading,
    },
  ] = useGetTaxSchemeByIdLazyQuery({})

  const [addTaxScheme, { loading: addTaxSchemeLoading }] =
    useAddTaxSchemeMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        if (data?.addTaxScheme) {
          setSnackBar(SystemMsgs.createNewRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })

  const [editTaxScheme, { loading: editTaxSchemeLoading }] =
    useEditTaxSchemeMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        if (data?.editTaxScheme) {
          setSnackBar(SystemMsgs.updateRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })
  //--End GraphQL--

  const onSubmit = data => {
    if (data?.details.length > 0) {
      const editId = mode === 'edit' ? { id: id } : null

      const submitData = {
        ...editId,
        name: data?.name,
        description: data?.description == '' ? null : data?.description,
        isExclusive: data?.isExclusive,
        taxSchemeDetail: data?.details?.map((x, i) => {
          return {
            detailId: x?.detailId,
            effectiveDate: new Date(x?.effectiveDate)
              ?.toISOString()
              ?.slice(0, 10),
            taxSchemeDetailItem: x?.items?.map((z, index) => {
              return {
                detailItemId: z.detailItemId,
                detailId: x?.detailId,
                taxId: z.tax?.taxId,
                classType: z.tax?.classType,
                computeMethod: z.computeMethod,
                seqNo: Number(z.seqNo),
                taxOnSeq: z.taxOnSeq?.map(y => {
                  return {
                    taxSchemeDetailItemId: y.detailItemId,
                    seqNo: Number(y.seqNo),
                  }
                }),
              }
            }),
          }
        }),
      }

      if (mode == 'add') {
        addTaxScheme({
          variables: {
            input: submitData,
          },
        })
      } else {
        editTaxScheme({
          variables: {
            input: submitData,
          },
        })
      }
    } else {
      setSnackBar('Create at least (1) tax scheme detail')
    }
  }

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => {
              navBack()
            },
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${rootState.profileData?.subscriptionName || ''}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: 'Tax Scheme',
          },
          rightText: mode === 'add' ? 'New' : 'Edit',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //#endregion

  useEffect(() => {
    if (mode == 'edit') {
      getTaxScheme({
        variables: {
          id: id,
        },
      })
    }
  }, [mode])

  useEffect(() => {
    //--region build data structure for useFieldArray--//
    if (
      getTaxSchemeById &&
      !getTaxSchemeTaxLoading &&
      getTaxSchemeById?.taxSchemeDetails[0] !== null
    ) {
      setValue('name', getTaxSchemeById?.name)
      setValue('description', getTaxSchemeById?.description)
      setValue('isExclusive', getTaxSchemeById?.isExclusive)
      getTaxSchemeById?.taxSchemeDetails?.map(el => {
        let tempItems = []
        el.taxSchemeDetailItems?.map(z => {
          const taxObj = getTaxSchemeTax?.find(e => e.taxId == z.taxId)
          let tempItem = {
            detailItemId: z?.id,
            seqNo: z?.seqNo.toString(),
            tax: taxObj,
            computeMethod: z?.computeMethod,
            taxOnSeq: z?.taxOnSeq?.map(x => {
              return {
                detailItemId: x.taxSchemeDetailItemId,
                seqNo: x.seqNo.toString(),
              }
            }),
          }
          tempItems.push(tempItem)
        })
        let tempDetail = {
          detailId: el.id,
          effectiveDate: new Date(el?.effectiveDate)
            ?.toISOString()
            ?.slice(0, 10),
          items: tempItems,
        }
        append(tempDetail)
      })
    }
    //--region build data structure for useFieldArray--//
  }, [getTaxSchemeById, getTaxSchemeTax])

  //to disable tax rate if current tax rate's effective date > today's date
  let disableAddTaxSchemeDetail = () => {
    let checking

    const minDate = new Date()?.toISOString()?.slice(0, 10)

    let lastIndex = fields[fields?.length - 1]
    checking = lastIndex?.effectiveDate > minDate

    if (checking == true) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <TaxSchemeDialogForm
        open={dialog}
        setOpen={setDialog}
        dialogMode={dialogMode}
        itemDetailFields={fields}
        itemDetailAppend={append}
        itemDetailUpdate={update}
        defaultValue={menu?.data}
        editIndex={menu?.index}
      />

      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <Container>
          <Card>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label={disableLabelAutocomplete('Name')}
                  autoComplete="off"
                  helperText={errors?.name?.message}
                  error={errors?.name ? true : false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  label="Description"
                  name="description"
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              )}
            />
            <Box width="100%" display="flex">
              <Box width="50%">
                <Typography paddingTop="6px" fontSize="16" variant="inherit">
                  Exclusive Tax
                </Typography>
              </Box>
              <Box width="50%" display="flex" justifyContent="flex-end">
                <Controller
                  control={control}
                  name="isExclusive"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Switch
                      value={value}
                      color="primary"
                      onChange={e => onChange(e.target.checked)}
                      onBlur={onBlur}
                      checked={value}
                    />
                  )}
                />
              </Box>
            </Box>
          </Card>

          <CardListHeader
            title={
              <Typography
                className="text-xsTitle"
                color="#FF9800"
                component="span"
                flex="1 1"
                variant="inherit"
              >
                Tax Scheme Detail
              </Typography>
            }
            action={
              <IconButton
                aria-label="Addbox"
                style={{
                  padding: '0px',
                }}
                disabled={disableAddTaxSchemeDetail()}
                onClick={() => {
                  setDialog(true)
                  setDialogMode('Add')
                }}
              >
                {disableAddTaxSchemeDetail() === true ? (
                  <AddBox color="disabled" />
                ) : (
                  <AddBox color="primary" />
                )}
              </IconButton>
            }
          >
            <>
              <List>
                {fields === undefined || fields?.length === 0 ? (
                  <EmptyState
                    title={'No Records'}
                    subTitle={'Click the add button to create a new record.'}
                  />
                ) : (
                  fields?.map((el, i) => {
                    return (
                      <ListItem
                        key={el.id}
                        disabled={
                          el?.effectiveDate <=
                          new Date()?.toISOString()?.slice(0, 10)
                        }
                      >
                        <ColumnFlex fullWidth>
                          <Box borderBottom="1px solid #fff">
                            <Typography
                              component="div"
                              display="flex"
                              variant="inherit"
                              color="common.black"
                            >
                              <Typography
                                className="text-icon text-desc"
                                component="span"
                                flex="1"
                                variant="inherit"
                              >
                                {formatDate(el?.effectiveDate.toString())}
                              </Typography>
                              {el?.effectiveDate <=
                              new Date()?.toISOString()?.slice(0, 10) ? null : (
                                <IconButton
                                  onClick={e =>
                                    handleClick(e, el.detailId, i, el)
                                  }
                                >
                                  <MoreVert />
                                </IconButton>
                              )}
                            </Typography>
                          </Box>
                          {el.items?.map((x, i) => {
                            return (
                              <CardListItem
                                key={x.detailItemId}
                                body={
                                  <ColumnFlex fullWidth>
                                    <Typography
                                      component="div"
                                      display="flex"
                                      variant="inherit"
                                      color="common.black"
                                      className="text-desc"
                                    >
                                      <RowFlex
                                        fullWidth
                                        mainAxisAlignment="space-between"
                                      >
                                        <Typography
                                          component="span"
                                          variant="inherit"
                                        >
                                          {x?.seqNo}.&nbsp;
                                          {x?.tax?.code}
                                          &emsp;
                                          {x?.tax?.rate}%
                                        </Typography>
                                        <TextX
                                          component="span"
                                          variant="inherit"
                                          data={x?.tax?.schemeType}
                                        />
                                      </RowFlex>
                                    </Typography>
                                    <Typography
                                      component="div"
                                      display="flex"
                                      variant="inherit"
                                      color="common.black"
                                      className="text-desc"
                                    >
                                      <RowFlex
                                        fullWidth
                                        mainAxisAlignment="space-between"
                                      >
                                        <TextX
                                          component="span"
                                          variant="inherit"
                                          data={x?.computeMethod}
                                        />
                                        <TextX
                                          component="span"
                                          variant="inherit"
                                          data={x?.tax?.classType}
                                        />
                                      </RowFlex>
                                    </Typography>
                                  </ColumnFlex>
                                }
                              />
                            )
                          })}
                        </ColumnFlex>
                      </ListItem>
                    )
                  })
                )}
              </List>
            </>
          </CardListHeader>
        </Container>

        <Menu
          id="default-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            onClick={() => {
              setDialog(true)
              setDialogMode('Edit')
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={() => remove(menu?.index)}>Delete</MenuItem>
        </Menu>

        <Footer
          buttons={[
            {
              children: 'Save',
              color: 'primary',
              onClick: handleSubmit(onSubmit),
            },
          ]}
        />
      </form>
    </>
  )
}

export default TaxSchemeForm
