import { lazy } from 'react'
import { RouteObject } from 'react-router'

const SalesCancellationAnalysis = lazy(
  () => import('./SalesCancellationAnalysis')
)

const SalesCancellationAnalysisRoutes: RouteObject[] = [
  {
    path: 'sales-cancellation-analysis',
    children: [
      {
        path: '',
        element: <SalesCancellationAnalysis />,
      },
    ],
  },
]

export default SalesCancellationAnalysisRoutes
