import { ColumnFlex, Container, EmptyState } from '@ifca-ui/core'
import { SalesContractSubheader } from 'components/General/SalesContractSubheader'
import useAppData from 'components/General/useAppData'
import {
  useGetPBdataListQuery,
  useGetSalesContractByIdQuery,
} from 'generated/graphql'
import { useMemo } from 'react'
import usePBheader from './PBcomponent/usePBheader'
import { PBtypeList } from './Pbtest/PBtypeList'

interface props {
  isPA: boolean
  addOnRef?: string
}

const PAprogressBillingListing = ({ isPA, addOnRef }: props) => {
  const { rootState, subHeaderRef, rootDispatch, saleID } = useAppData()

  usePBheader(isPA)

  // const {
  //   data: { getNewPBdata } = { getNewPBdata: {} },
  // } = useGetNewPBdataQuery({
  //   variables: { saleId: saleID },
  // })

  // #region get salesContractData
  const { data: salesContractData = { getSalesContractById: null } } =
    useGetSalesContractByIdQuery({
      variables: { saleID },
    })
  // #end region

  const {
    data: { getPBdataList } = { getPBdataList: {} },
    refetch: refetchData,
  } = useGetPBdataListQuery({
    variables: { saleId: saleID },
  })

  const renderPBList = keys => {
    return (
      <ColumnFlex gap="1rm">
        {keys.map(key => (
          <PBtypeList
            isPA={isPA}
            pbListData={getPBdataList[key]}
            refetchData={refetchData}
            type={''}
          />
        ))}
      </ColumnFlex>
    )
  }

  const { totalBilled, totalOutstanding } = useMemo(() => {
    if (Object.keys(getPBdataList).length === 0)
      return { totalBilled: 0, totalOutstanding: 0 }
    let refs = addOnRef
      ? [addOnRef]
      : ['sale_product', 'sale_subproduct', 'sale_package']
    let billed = 0
    let outstanding = 0
    refs.forEach(ref => {
      getPBdataList[ref].forEach(data => {
        data?.['PBdata'].forEach(pb => {
          if (pb['has_posted']) {
            billed += pb['sumOrangeAmount'] ?? 0
            outstanding += pb['sumRedAmount'] ?? 0
          }
        })
      })
    })
    return { totalBilled: billed, totalOutstanding: outstanding }
  }, [getPBdataList])

  return (
    <>
      <SalesContractSubheader
        getSalesContractById={salesContractData.getSalesContractById}
        billedSummary={{
          totalBilled,
          totalOutstanding,
        }}
      />
      <Container>
        {addOnRef ? (
          getPBdataList[addOnRef]?.length === 0 ? (
            <EmptyState title="No Record found" />
          ) : (
            renderPBList([addOnRef])
          )
        ) : ['sale_product', 'sale_subproduct', 'sale_package']?.every(
            key => getPBdataList[key]?.length === 0
          ) ? (
          <EmptyState title="No Record found" />
        ) : (
          renderPBList(['sale_product', 'sale_subproduct', 'sale_package'])
        )}
      </Container>
    </>
  )
}
export default PAprogressBillingListing
