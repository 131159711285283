import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Container,
  Dialog,
  DialogButton,
  EmptyState,
  Footer,
  ListItemText,
  Menu,
  RowFlex,
  SizedBox,
  Subheader,
  SubheaderText,
  useMenuOption,
} from '@ifca-ui/core'
import { useInView } from '@ifca-ui/core/node_modules/react-intersection-observer'
import { AddBox, MoreVert, QrCodeScannerTwoTone } from '@mui/icons-material'
import DevicesFoldIcon from '@mui/icons-material/DevicesFold'
import {
  Autocomplete,
  Box,
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { ActionDialog } from 'components/Form/ActionDialog'
import { CardListHeader, CardListItem } from 'components/General/CardListItem'
import IconX from 'components/General/IconX'
import { TopSectionHeader } from 'components/Header/HeaderSection'
import {
  CustomPhoneInput,
  formatPhoneNumber,
} from 'components/Inputs/CustomPhoneInput'
import { VoiceTextField } from 'components/Inputs/CustomTextfield'
import { NricTextField } from 'components/Inputs/NricTextField'
import useNav from 'components/RouteService/useNav'
import AppContext, { AppContextProps } from 'containers/context/Context'
import {
  JointBuyerInput,
  SaleStatus,
  useAddJointBuyerMutation,
  useEditJointBuyerMutation,
  useGetAllMasterDataQuery,
  useGetContactByIdLazyQuery,
  useGetContactDetailByKeyDataLazyQuery,
  useGetCustomerOriginQuery,
  useGetGeneralTinByPartyLazyQuery,
  useGetMainPurchaserAddressBySaleIdLazyQuery,
  useGetPurchaserInfoListingQuery,
  useGetSaleContactListTestLazyQuery,
  useProjectDetailQuery,
} from 'generated/graphql'
import { useCheckboxGroup } from 'helpers/Hooks/useCheckboxGroup'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { disableLabelAutocomplete } from 'helpers/StringNumberFunction/formatLabel'
import { CommonYupValidation } from 'helpers/yup'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router'
import * as yup from 'yup'
import { useSRegState } from '../components/SRegState'
import {
  addressProps,
  PurchaserInfoAddressDialog,
} from './PurchaserInfoAddressDialog'

interface standardParameterProps {
  id: string
  name: string
  defaulted?: string
}

interface companyListProps {
  companyName: string
}

interface buyerDetailProps {
  id: string
  fullName: string
  email?: string
  phoneNo?: string
  gender?: string
  icNo?: string
  companyName?: string
  companyRegNo?: string
}

interface CustomerOriginSelect {
  id: string
  name: string
}

export interface PurchaserInfoProps {
  Nationality: standardParameterProps
  IdentityType: string
  IdentityNo: string
  Name?: buyerDetailProps | string
  Salutation: standardParameterProps
  Race: standardParameterProps
  MaritalStatus: standardParameterProps
  Gender: string
  CustomerOrigin: CustomerOriginSelect
  Email: string
  HomeTelNo: string
  MobileNo: string
  OfficeTelNo: string
  Remark: string
  Tin?: string
  SstNo?: string
  CompanyName?: companyListProps
  CompanyReg?: string
  DirectorName?: string
  mode: string
  bumiputera: boolean
  vip: boolean
  foreignPurchase: boolean
  addressList: addressProps[]
  mpAddressList: addressProps[]
}

export const PurchaseInfoForm = (props: any) => {
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()
  let location = useLocation()

  const { rootState, rootDispatch } =
    React.useContext<AppContextProps>(AppContext)
  const { id, salesStatus, isMainBuyer, jointBuyerId } = location?.state
  const { mode } = props
  const { projectID, saleID }: any = useParams()

  const [record, setRecord] = useState<boolean>(false)
  // const [ogAddr, setOGAddr] = useState(null)
  // const [addrValue, setAddress] = useState(null)
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [subContactList, setSubContactList] = useState([])

  const CompanyPurchaseCheck = useRef(false)

  const [radioState, setRadioState] = useState('')
  const [isSetDefault, setIsSetDefault] = useState(false)
  const [contactId, setContactId] = useState(null)
  const [dialogMode, setDialogMode] = useState<'Add' | 'Edit' | ''>('')
  const [existingContact, setExistingContact] = useState([])
  const [updatedSubContactList, setUpdatedSubContactList] = useState([])
  const [addressDialog, setAddressDialog] = React.useState(false)
  const [copyAddressDialog, setCopyAddressDialog] = React.useState(false)
  const [importContactDialog, setImportContactDialog] = React.useState(false)

  const [emailChecked, setEmailChecked] = React.useState(false)
  const [letterChecked, setLetterChecked] = React.useState(false)

  const apiCalledRef = useRef(false)
  const isMainPurchaser = useRef(false)
  const contactRef = useRef({ id: '', name: '', icNo: '', phoneNo: '' })
  const [checkContactExist, setCheckContactExist] = React.useState(false)
  const [contactInfo, setContactInfo] = React.useState(null)

  const [hasMore, setHasMore] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const offsetRef = useRef(0)
  const limit = 10
  const hasMounted = useRef(false)

  const { SRegState, setSRegState } = useSRegState()

  //#region schema validation
  const FormTemplateSchema = yup.object().shape({
    mode: yup.string(),
    IdentityType: CommonYupValidation.requireField('Identity Type is required'),
    IdentityNo: yup
      .string()
      .when(['mode', 'IdentityType'], (pageMode, value) => {
        switch (value) {
          case 'IDENTITY_CARD':
            return CommonYupValidation.NRIC()
          case 'PASSPORT':
            return CommonYupValidation.requireField('Passport is required')
          case 'MILITARY_CARD':
            return CommonYupValidation.requireField('Military Card is required')
          case 'BUSINESS_LICENSE':
            return CommonYupValidation.requireField(
              'Business Registration No. (BRN) is required'
            )
          case '':
            return CommonYupValidation.NRIC()
        }
      })
      .required('Identity No is required'),
    Name: !CompanyPurchaseCheck.current
      ? yup.mixed().nullable().required('Name is required')
      : null,
    CustomerOrigin: CommonYupValidation.requireArray(
      'Customer Origin is required'
    ),
    Email: yup
      .string()
      .required('Email Address is required')
      .email('Email Address is invalid'),
    MobileNo: CommonYupValidation.checkPhone(true, 'Mobile No. is required'),
    OfficeTelNo: CommonYupValidation.checkPhone().nullable(),
    HomeTelNo: CommonYupValidation.checkPhone().nullable(),
    CompanyName: !CompanyPurchaseCheck.current
      ? null
      : yup.mixed().nullable().required('Company Name is required'),
    CompanyReg: !CompanyPurchaseCheck.current
      ? null
      : CommonYupValidation.requireField(
          'Business Registration No. (BRN) is required'
        ),
    DirectorName: !CompanyPurchaseCheck.current
      ? null
      : CommonYupValidation.requireField(
          'Director Name is required'
        ).nullable(),
  })
  //#endregion

  //#region use form
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<PurchaserInfoProps>({
    mode: 'onSubmit',
    resolver: yupResolver(FormTemplateSchema),
    defaultValues: {
      Nationality: null,
      IdentityType: '',
      IdentityNo: '',
      Name: null,
      Salutation: null,
      Race: null,
      MaritalStatus: null,
      Gender: '',
      CustomerOrigin: null,
      Email: '',
      HomeTelNo: '',
      MobileNo: '',
      OfficeTelNo: '',
      Remark: '',
      CompanyName: null,
      CompanyReg: '',
      DirectorName: '',
      mode: mode,
      addressList: [],
      mpAddressList: [],
      bumiputera: false,
      foreignPurchase: false,
      vip: false,
      Tin: '',
      SstNo: '',
    },
  })

  const { fields, append, update, remove, replace } = useFieldArray({
    control,
    name: 'addressList',
  })

  const { fields: mpFields, append: mpAppend } = useFieldArray({
    control,
    name: 'mpAddressList',
  })

  //#endregion

  //#region get project detail api
  const {
    loading: curProjectLoading,
    error: curProjectError,
    data: { projectDetail } = { projectDetail: null },
  } = useProjectDetailQuery({
    variables: {
      id: projectID,
    },
  })
  //#endregion

  //#region get master data api
  const {
    loading: getMasterDataLoading,
    error: getMasterDataError,
    data: { GetAllMasterInfo } = {
      GetAllMasterInfo: {
        Genders: [],
        MaritalStatus: [],
        Races: [],
        Salutations: [],
        Nationalities: [],
      },
    },
  } = useGetAllMasterDataQuery({})
  //#endregion

  //#region get sale contact list api
  const [
    getSaleContact,
    {
      data: { getSaleContactListTest } = { getSaleContactListTest: [] },
      loading: getSaleContactListTestLoading,
    },
  ] = useGetSaleContactListTestLazyQuery({
    onCompleted: data => {
      if (data.getSaleContactListTest) {
        const contactList = data?.getSaleContactListTest
        const tempList = contactList?.map(el => {
          return {
            ...el,
            label: `${el?.fullName} / ${el.icNo}`,
            companyLabel: `${el?.companyName} / ${el.companyRegNo}`,
          }
        })
        setSubContactList(prevList => [...prevList, ...tempList])
        if (tempList.length < limit) {
          setHasMore(false)
        }
        offsetRef.current = subContactList.length + tempList.length
        setDropdownLoading(false)
      }
    },
  })
  //#endregion

  //#region get all main purchaser address
  const [
    getMainPurchaserAddress,
    {
      data: { getMainPurchaserAddressBySaleId } = {
        getMainPurchaserAddressBySaleId: null,
      },
    },
  ] = useGetMainPurchaserAddressBySaleIdLazyQuery({
    variables: {
      saleId: saleID,
    },
    onCompleted: data => {
      data.getMainPurchaserAddressBySaleId.map(i => {
        mpAppend({
          addressId: null,
          isPrimary: i.isPrimary,
          addressType: i.address.AddressType,
          address: i.address,
        })
      })
    },
  })

  useEffect(() => {
    replace(mpFields)
  }, [mpFields])
  //#endregion

  //#region get all contact list graphql
  const [getContact, { data: { getContactById } = { getContactById: null } }] =
    useGetContactByIdLazyQuery({
      onCompleted: data => {
        setContactInfo(data.getContactById)
        contactRef.current.id = data.getContactById?.id
        contactRef.current.name = data.getContactById?.fullName
        contactRef.current.icNo = data.getContactById?.icNo
        contactRef.current.phoneNo =
          data.getContactById?.phoneNo === null
            ? undefined
            : data.getContactById?.phoneNo

        data.getContactById.sales.forEach(item => {
          if (item.id === saleID) {
            isMainPurchaser.current = true
          }
        })
      },
    })
  //#endregion

  //#region check existing contact
  const [
    getExistingContact,
    {
      data: { getContactDetailByKeyData } = { getContactDetailByKeyData: null },
    },
  ] = useGetContactDetailByKeyDataLazyQuery({
    onCompleted: data => {
      setCheckContactExist(false)

      if (!data?.getContactDetailByKeyData) {
        return
      }
      contactRef.current.name = data.getContactDetailByKeyData?.fullName
      contactRef.current.icNo = data.getContactDetailByKeyData?.icNo
      contactRef.current.phoneNo =
        data.getContactDetailByKeyData?.phoneNo === null
          ? undefined
          : data.getContactDetailByKeyData?.phoneNo
      setImportContactDialog(true)
    },
  })
  //#endregion

  //#region get general tin by party
  const [
    getGeneralTin,
    { data: { getGeneralTinByParty } = { getGeneralTinByParty: null } },
  ] = useGetGeneralTinByPartyLazyQuery({
    onCompleted: data => {
      setValue('Tin', data?.getGeneralTinByParty?.tin ?? '')
    },
  })
  //#endregion

  //#region add purchaser graphql
  const [addPurchaser] = useAddJointBuyerMutation({
    onError: error => {},
    onCompleted: data => {
      setSnackBar(SystemMsgs.createNewRecord())
      setSRegState({
        ...SRegState,
        summary: {
          ...SRegState.summary,
          status: 'back',
        },
        PurchaserInfo: {
          ...SRegState.PurchaserInfo,
          status: 'refresh',
        },
      })
      navBack()
    },
  })
  //#endregion

  //#region edit purchaser
  const [editPurchaserApi] = useEditJointBuyerMutation({
    onError: error => {},
    onCompleted: data => {
      setSnackBar(SystemMsgs.updateRecord())
      setSRegState({
        ...SRegState,
        summary: {
          ...SRegState.summary,
          status: 'back',
        },
        PurchaserInfo: {
          ...SRegState.PurchaserInfo,
          status: 'refresh',
        },
      })
      navBack()
    },
  })
  //#endregion

  //#region get existing contact to filter out from contact listing
  const {
    refetch: getPurchaserInfoListingRefetch,
    data: { getPurchaserInfoListing } = { getPurchaserInfoListing: [] },
    loading: getPurchaserInfoListingLoading,
  } = useGetPurchaserInfoListingQuery({
    variables: {
      saleId: saleID,
    },
    onCompleted: data => {
      setExistingContact(data.getPurchaserInfoListing)
    },
  })
  //#endregion

  const {
    refetch: getCustomerOriginRefetch,
    data: { getCustomerOrigin: customerOriginList } = { getCustomerOrigin: [] },
    loading: getCustomerOriginLoading,
  } = useGetCustomerOriginQuery({})

  useEffect(() => {
    if (mode === 'edit') {
      getContact({
        variables: {
          contactId: id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (mode === 'edit' && !contactRef.current.id) {
      return
    }
    fetchOptions()
  }, [CompanyPurchaseCheck.current, contactRef.current.id])

  useEffect(() => {
    if (mode == 'add' && Object.keys(GetAllMasterInfo)?.length > 0) {
      const defaultSalutation = Salutations?.find(el => el.defaulted == true)
      const defaultRace = Races?.find(el => el.defaulted == true)
      const defaultMarital = MaritalStatus?.find(el => el.defaulted == true)

      if (mode == 'add') {
        if (defaultSalutation) {
          setValue('Salutation', defaultSalutation)
        }
        if (defaultRace) {
          setValue('Race', defaultRace)
        }
        if (defaultMarital) {
          setValue('MaritalStatus', defaultMarital)
        }

        setValue('HomeTelNo', '+60')
        setValue('MobileNo', '+60')
        setValue('OfficeTelNo', '+60')
      }
    }

    if (contactInfo && Object.keys(GetAllMasterInfo)?.length > 0) {
      const nationalityObject = Nationalities.find(
        el => el.id == contactInfo?.nationality?.id
      )

      const defaultSalutation = Salutations?.find(
        el => el.id == contactInfo?.salutation?.id
      )
      const defaultRace = Races?.find(el => el.id == contactInfo?.race?.id)
      const defaultMarital = MaritalStatus?.find(
        el => el.id == contactInfo?.maritalStatus?.id
      )

      const contactExt = contactInfo?.contactExt

      const curCustOrigin = customerOriginList?.find(
        x => x.id == contactExt?.customerOrigin
      )
      setValue('Name', contactInfo?.fullName ?? '')
      setValue('Nationality', nationalityObject ?? null)
      setValue('Salutation', defaultSalutation ?? null)
      setValue('Race', defaultRace ?? null)
      setValue('MaritalStatus', defaultMarital ?? null)
      setValue('IdentityType', contactExt?.identityType ?? '')
      setValue('IdentityNo', contactInfo?.icNo ?? '')
      setValue('Email', contactInfo?.email ?? '')
      setValue(
        'HomeTelNo',
        contactInfo?.homeTel === null ? '+60' : contactInfo?.homeTel
      )
      setValue(
        'MobileNo',
        contactInfo?.phoneNo === null ? '+60' : contactInfo?.phoneNo
      )
      setValue(
        'OfficeTelNo',
        contactInfo?.officeTel === null ? '+60' : contactInfo?.officeTel
      )

      setValue('Gender', contactInfo?.gender ?? '')
      setValue('CustomerOrigin', curCustOrigin ?? null)
      setValue('Remark', contactExt?.remark ?? '')
      setValue('Tin', contactExt?.tin ?? '')
      setValue('SstNo', contactExt?.sstNo ?? '')

      if (contactInfo?.companyName && contactInfo?.companyRegNo) {
        CompanyPurchaseCheck.current = true
        setValue('CompanyReg', contactInfo?.companyRegNo ?? '')
      } else {
        CompanyPurchaseCheck.current = false
      }

      setValue('bumiputera', contactExt?.isBumi ?? false)
      setValue('vip', contactExt?.isVip ?? false)
      setValue('foreignPurchase', contactExt?.isForeignPurchase ?? false)

      setCheckedItems({
        sms: contactExt?.subscribeMaterialSms ?? false,
        whatsapp: contactExt?.subscribeMaterialWhatsapp ?? false,
        emailBlast: contactExt?.subscribeMaterialEmail ?? false,
        purchaserApp: contactExt?.subscribeMaterialPaNews ?? false,
      })

      if (contactExt?.mailingMethod == 'Email') {
        setRadioState('Email')
      }
      if (contactExt?.mailingMethod == 'Letter') {
        setRadioState('Letter')
      }
      if (contactExt?.mailingMethod == 'Email & Letter') {
        setRadioState('Email & Letter')
      }
      replace(
        contactInfo?.addressList?.map(el => ({
          addressId: el.id,
          isPrimary: el.isPrimary,
          addressType: el.address.AddressType,
          address: el.address,
        }))
      )
    }
  }, [contactInfo, GetAllMasterInfo])

  useEffect(() => {
    if (subContactList?.length !== 0 && !isSetDefault && contactInfo) {
      const nameObject = subContactList?.find(el => el.id == contactInfo?.id)

      setContactId(nameObject?.id)
      if (nameObject) {
        if (!CompanyPurchaseCheck.current) setValue('Name', nameObject)

        if (CompanyPurchaseCheck.current) setValue('CompanyName', nameObject)

        if (CompanyPurchaseCheck.current)
          setValue('DirectorName', nameObject?.fullName)

        setIsSetDefault(true)
      }
    }
  }, [subContactList, CompanyPurchaseCheck.current, contactInfo])

  //#region to exclude existing contact from contact list
  useEffect(() => {
    if (subContactList) {
      let excludeid = existingContact.map(item => item?.id)
      let updatedList = subContactList.filter(x => !excludeid.includes(x?.id))
      setUpdatedSubContactList(updatedList)
    }
  }, [subContactList])
  //#endregion

  //#region header/subheader
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => {
              setSRegState({
                ...SRegState,
                summary: {
                  ...SRegState.summary,
                  status: 'back',
                },
              })
              navBack()
            },
          },
        },
        rightIcon: {
          // icon: 'switch',
        },
        topSection: {
          smTitle: TopSectionHeader,
          title: `${projectDetail?.name ?? '-'}`,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 3,
            current: isMainBuyer ? 'Main Buyer' : 'Joint Buyer',
          },
          rightText: '',
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetail, contactInfo])
  //#endregion

  const assignTin = () => {
    const isForeign = watch('foreignPurchase')
    const custOrigin = watch('CustomerOrigin.id')
    let selectedTinParty: string = ''

    if (custOrigin === 'GOVERNMENT_STAFF') {
      selectedTinParty = 'Government'
    } else if (custOrigin === 'PUBLIC') {
      selectedTinParty = 'GeneralBuyer'
    } else if (isForeign) {
      selectedTinParty = 'ForeignBuyer'
    } else {
      setValue('Tin', '')
      return
    }

    getGeneralTin({
      variables: {
        party: selectedTinParty,
      },
    })
  }

  // #region useCheckBoxGroup
  const MarketingMaterials = [
    { id: 'sms', name: 'SMS' },
    { id: 'whatsapp', name: 'WhatsApp' },
    { id: 'emailBlast', name: 'Email Blast' },
    { id: 'purchaserApp', name: 'Purchaser App > News' },
  ]

  const {
    checkedItems,
    parentChecked,
    parentIndeterminate,
    toggleParent,
    toggleChild,
    setCheckedItems,
  } = useCheckboxGroup({
    initialValues: MarketingMaterials.map(el => el.id),
  })
  // #endregion

  const resetCompanyField = () => {
    reset()
    toggleParent(false)
    setRadioState('')
  }

  const onSubmit = (data: PurchaserInfoProps) => {
    if (mailingMethod === '') {
      setSnackBar('Please select at least one mailing method')
      return false
    }

    if (data.addressList?.length == 0) {
      setSnackBar(SystemMsgs.multipleAddress())
      return
    }

    if (!data?.addressList?.find(el => el.isPrimary)) {
      setSnackBar(SystemMsgs.correspondenceAddress())
      return
    }

    const checkingAddressList = data?.addressList?.map((x, index, array) => {
      const previousAddressId = index === 0 ? x?.addressId : array[0].addressId

      if (index !== 0 && x?.addressId === previousAddressId) {
        return { ...x, addressId: null }
      }
      return x
    })

    const existingId =
      mode != 'add' ? { ID: id, jointBuyerId: jointBuyerId } : null

    let mobileNo = formatPhoneNumber(data?.MobileNo)

    const submitData: JointBuyerInput = {
      companyPurchase: CompanyPurchaseCheck.current,
      fullName: CompanyPurchaseCheck.current
        ? data.DirectorName
        : typeof data.Name === 'object'
          ? data.Name.fullName
          : data.Name,
      email: data.Email,
      phoneNo: mobileNo,
      // DOB: Date;
      icNo: data.IdentityNo,
      homeTel: formatPhoneNumber(data?.HomeTelNo),
      officeTel: formatPhoneNumber(data?.OfficeTelNo),
      companyName: CompanyPurchaseCheck.current
        ? typeof data.CompanyName === 'object'
          ? data.CompanyName.companyName
          : data.CompanyName
        : null,
      companyRegNo: CompanyPurchaseCheck.current ? data.CompanyReg : null,
      race: data?.Race?.id,
      salutation: data?.Salutation?.id,
      maritalStatus: data?.MaritalStatus?.id,
      nationality: data?.Nationality?.id,
      gender: data?.Gender,
      // incomeRange: data?.
      identityType: data?.IdentityType,
      customerOrigin: data?.CustomerOrigin?.id,
      isBumi: data?.bumiputera,
      isVip: data?.vip,
      isForeignPurchase: data?.foreignPurchase,
      subscribeMaterialSms: checkedItems?.sms,
      subscribeMaterialWhatsapp: checkedItems?.whatsapp,
      subscribeMaterialEmail: checkedItems?.emailBlast,
      subscribeMaterialPaNews: checkedItems?.purchaserApp,
      mailingMethod: mailingMethod,
      remark: data?.Remark,
      tin: data?.Tin,
      sstNo: data?.SstNo,
      addressListing: checkingAddressList.map(x => {
        return {
          address: {
            AddressType: x?.address?.AddressType,
            address: x?.address?.address,
            city: x?.address?.city,
            country: x?.address?.country,
            postCode: x?.address?.postCode,
            state: x?.address?.state,
          },
          isPrimary: x?.isPrimary,
          addressId: x?.addressId || null,
        }
      }),
    }

    const existingBuyer = getPurchaserInfoListing.filter(item => {
      const nameMatch = item.name === submitData.fullName
      const icMatch = item.icNo === submitData.icNo
      const phoneMatch = item.phoneNo === submitData.phoneNo
      const editIdNotMatch = mode === 'edit' ? id !== item.id : true
      return nameMatch && icMatch && phoneMatch && editIdNotMatch
    })

    existingBuyer.length === 1
      ? setSnackBar(
          'Creation failed. A record for this contact already exists.'
        )
      : mode === 'add'
        ? addPurchaser({
            variables: {
              input: submitData,
              saleId: saleID,
            },
          })
        : editPurchaserApi({
            variables: {
              input: {
                ...submitData,
                isMainBuyer: isMainBuyer,
                jointBuyerId: jointBuyerId,
                editId: id,
              },
              saleId: saleID,
            },
          })

    // if (!submitData?.AddressMultiple) {
    //   setSnackBar(SystemMsgs.multipleAddress())
    // } else {
    //   let temp = submitData?.AddressMultiple.filter(x => x?.is_primary === true)
    //   if (temp === undefined || temp?.length === 0 || temp === null) {
    //     setSnackBar(SystemMsgs.correspondenceAddress())
    //   } else {
    //     if (mode != 'add') {
    //       editPurchaserApi({
    //         variables: {
    //           input: submitData,
    //           saleId: saleID,
    //         },
    //       })
    //     } else {

    //     }
    //   }
    // }
  }

  //#region enum listing start
  const IdentityTypeListing = [
    {
      ID: 'IDENTITY_CARD',
      Name: 'Identity Card',
    },
    { ID: 'PASSPORT', Name: 'Passport' },
    { ID: 'MILITARY_CARD', Name: 'Military Card' },
    { ID: 'BUSINESS_LICENSE', Name: 'Business Registration No. (BRN)' },
  ]

  const CustomerOriginListing = [
    { id: 'STAFF_PURCHASE', Name: 'Staff Purchaser' },
    { id: 'PUBLIC', Name: 'Public' },
    { id: 'GOVERNMENT_STAFF', Name: 'Government Staff' },
    { id: 'COMPANY_PURCHASE', Name: 'Company Purchase' },
  ]
  const CustomerOriginMap = CustomerOriginListing.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})

  const { Genders, MaritalStatus, Races, Salutations, Nationalities }: any =
    GetAllMasterInfo
  //#endregion

  const triggerChange = isCompany => {
    fetchOptions()
  }

  const fetchOptions = useCallback(() => {
    if (getSaleContactListTestLoading || !hasMore) return

    setDropdownLoading(true)
    getSaleContact({
      variables: {
        isCompany: CompanyPurchaseCheck.current,
        offset: offsetRef.current,
        search: searchQuery,
        currentContactId: contactRef.current.id,
      },
    })
    setDropdownLoading(false)
  }, [searchQuery, hasMore, getSaleContactListTestLoading])

  // Intersection Observer
  const { ref: inViewRef, inView } = useInView({
    threshold: 1.0,
    triggerOnce: false, // Keep triggering if scrolling repeatedly
  })

  useEffect(() => {
    if (inView && hasMore && !dropdownLoading) {
      fetchOptions()
    }
  }, [inView, hasMore])

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true
      return // Prevent the effect from running on initial render
    }
    setDropdownLoading(false)
    offsetRef.current = 0
    setSubContactList([])
    setHasMore(true)
    fetchOptions()
  }, [searchQuery])

  const debouncedSearchQuery = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query)
      }, 1000),
    []
  )

  const setDetails = data => {
    const nationality = Nationalities?.find(x => x.id == data?.nationality?.id)
    const salutation = Salutations?.find(x => x.id == data?.salutation?.id)
    const race = Races?.find(x => x.id == data?.race?.id)
    const maritalSataus = MaritalStatus?.find(
      x => x.id == data?.maritalStatus?.id
    )
    if (data?.companyName !== null) {
      setValue('DirectorName', data?.fullName)
      setValue('CompanyName', data)
    }

    if (typeof data === 'object' && data !== null) {
      setValue('Name', data)
      setValue('IdentityType', data?.contactExt?.identityType)
      setValue('IdentityNo', data?.icNo)
      setValue('Nationality', nationality)
      setValue('Salutation', salutation)
      setValue('Race', race)
      setValue('MaritalStatus', maritalSataus)
      setValue('Gender', data?.gender)
      setValue('CustomerOrigin', {
        id: data?.contactExt?.customerOrigin,
        name: CustomerOriginMap[data?.contactExt?.customerOrigin].Name,
      })
      setValue('Email', data?.email)
      setValue('HomeTelNo', data?.homeTel)
      setValue('MobileNo', data?.phoneNo)
      setValue('OfficeTelNo', data?.officeTel)
      setValue('CompanyReg', data?.companyRegNo)
      setValue('bumiputera', data?.contactExt?.isBumi ?? false)
      setValue('foreignPurchase', data?.contactExt?.isForeignPurchase ?? false)
      setValue('vip', data?.contactExt?.isVip ?? false)

      setCheckedItems({
        sms: data?.contactExt?.subscribeMaterialSms ?? false,
        whatsapp: data?.contactExt?.subscribeMaterialWhatsapp ?? false,
        emailBlast: data?.contactExt?.subscribeMaterialEmail ?? false,
        purchaserApp: data?.contactExt?.subscribeMaterialPaNews ?? false,
      })

      if (data?.contactExt?.mailingMethod == 'Email') {
        setRadioState('Email')
      }
      if (data?.contactExt?.mailingMethod == 'Letter') {
        setRadioState('Letter')
      }
      if (data?.contactExt?.mailingMethod == 'Email & Letter') {
        setRadioState('Email & Letter')
      }
      setValue('Remark', data?.contactExt?.remark ?? '')
      setValue('Tin', data?.contactExt?.tin ?? '')
      setValue('SstNo', data?.contactExt?.sstNo ?? '')
    }

    // if (data?.address?.address) {
    // let array_of_address = data?.address.address ? [data.address.address] : []
    // setOGAddr(array_of_address) //to compare if there is any changes
    // setAddress(array_of_address?.slice(0))
    // }
  }

  //#region delete dialog
  const [deleteDialog, setDeleteDialog] = React.useState(false)

  const DeleteDialog = (
    <Dialog
      fullWidth={true}
      open={deleteDialog}
      onClose={() => setDeleteDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Address
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="primary.dark"
                    component="span"
                    variant="inherit"
                  >
                    Delete
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box>
          <Box width="100%" display="flex">
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-xsTitle"
            >
              Are you sure want to delete this address?
            </Typography>
          </Box>
          <Box width="100%" display="flex" paddingTop={'10px'}>
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-mdDesc"
            >
              {menu?.data?.address?.AddressType}
            </Typography>
          </Box>
          <Box width="100%" display="flex" paddingTop={'5px'}>
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-mdDesc"
            >
              {menu?.data?.address?.address} {menu?.data?.address?.city}{' '}
              {menu?.data?.address?.country}
            </Typography>
          </Box>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => setDeleteDialog(false),
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => handleDeleteAddress(menu?.index),
          },
        ],
      }}
    />
  )
  //#enderegion

  const handleDeleteAddress = index => {
    setDeleteDialog(false)
    remove(index)
  }

  //#region address copy dialog
  const AddressCopyDialog = (
    <ActionDialog
      title={"Copy Main Purchaser's Address"}
      label={
        fields.length > 0
          ? 'Do you want to remove all existing addresses and copy all addresses from main purchaser?'
          : "Do you want to copy main purchaser's addresses?"
      }
      action={() => {
        if (!apiCalledRef.current) {
          apiCalledRef.current = true
          getMainPurchaserAddress({})
        } else replace(mpFields)
        setCopyAddressDialog(false)
      }}
      dialogAction={copyAddressDialog}
      setOpenDialog={setCopyAddressDialog}
    />
  )
  //#enderegion

  //#region import contact dialog
  const ImportContactDialog = (
    <Dialog
      fullWidth={true}
      open={importContactDialog}
      onClose={() => setImportContactDialog(false)}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Import Contact Info
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <>
          <Box width="100%" marginBottom="14px">
            <Typography
              component="span"
              flex="1 1"
              variant="inherit"
              className="text-xsTitle"
            >
              Contact with following info already existed, do you want to import
              corresponding details?
            </Typography>
          </Box>
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography
              noWrap
              component="div"
              color="grey.600"
              fontSize="10px"
              fontWeight={300}
              lineHeight="16px"
            >
              Name
            </Typography>
            <Typography
              noWrap
              component="div"
              color="common.black"
              fontSize="12px"
              fontWeight={400}
              lineHeight="16px"
            >
              {contactRef.current.name}
            </Typography>
          </Box>
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography
              noWrap
              component="div"
              color="grey.600"
              fontSize="10px"
              fontWeight={300}
              lineHeight="16px"
            >
              Identity No
            </Typography>
            <Typography
              noWrap
              component="div"
              color="common.black"
              fontSize="12px"
              fontWeight={400}
              lineHeight="16px"
            >
              {contactRef.current.icNo}
            </Typography>
          </Box>
          <Box width="100%" boxSizing="border-box" marginBottom="14px">
            <Typography
              noWrap
              component="div"
              color="grey.600"
              fontSize="10px"
              fontWeight={300}
              lineHeight="16px"
            >
              Mobile No
            </Typography>
            <Typography
              component="div"
              color="common.black"
              fontSize="12px"
              fontWeight={400}
              lineHeight="16px"
            >
              {contactRef.current.phoneNo}
            </Typography>
          </Box>
        </>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => {
              setImportContactDialog(false)
            },
          },
          {
            children: 'Confirm',
            color: 'primary',
            onClick: () => {
              setContactInfo(getContactDetailByKeyData)
              setImportContactDialog(false)
            },
          },
        ],
      }}
    />
  )
  //#enderegion

  //handle mailing method checkbox
  const mailingMethod = useMemo(() => {
    if (emailChecked && letterChecked) {
      return 'Email & Letter'
    } else if (emailChecked) {
      return 'Email'
    } else if (letterChecked) {
      return 'Letter'
    } else {
      return ''
    }
  }, [emailChecked, letterChecked])

  useEffect(() => {
    if (radioState === 'Email & Letter') {
      setEmailChecked(true)
      setLetterChecked(true)
    } else if (radioState === 'Email') {
      setEmailChecked(true)
    } else if (radioState === 'Letter') {
      setLetterChecked(true)
    }
  }, [radioState])
  //endregion

  //handle addressListing display
  const addressListingItems = (items: any) =>
    items?.map((el, index) => (
      <CardListItem
        key={el.id}
        id={el.id}
        itemButton={
          <>
            {/* <Switch
                checked={activeListingIndex === index}
                onChange={() =>
                  setActiveListingIndex(prevIndex =>
                    prevIndex === index ? null : index
                  )
                }
              /> */}
            <IconButton
              edge="end"
              aria-label="more"
              aria-controls="menu-list"
              aria-haspopup="true"
              style={{
                padding: '0px 12px',
              }}
              onClick={e => handleClick(e, el?.address?.AddressType, index, el)}
            >
              <MoreVert />
            </IconButton>
          </>
        }
        body={
          <ListItemText
            primary={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  flex="1 1"
                  component="span"
                  variant="inherit"
                  className="text-xsTitle"
                >
                  {el?.address?.AddressType}
                </Typography>
                <Typography className="text-xsTitle" color="primary">
                  {el?.isPrimary ? 'Primary' : ''}
                </Typography>
              </div>
            }
            secondary={
              <Typography
                flex="1 1"
                component="span"
                variant="inherit"
                className="text-xsTitle"
              >
                {el?.address?.address} {el?.address?.city}{' '}
                {el?.address?.country}
              </Typography>
            }
          />
        }
      />
    ))
  //endregion

  //handle name, icNo, phoneNo onBlur event
  const handleBlurEvent = () => {
    const { Name: fullName, MobileNo: phoneNo, IdentityNo: icNo } = watch()
    const isNewContact = !(
      contactRef.current.name === fullName &&
      (contactRef.current.phoneNo === phoneNo ||
        contactRef.current.phoneNo === '+60') &&
      contactRef.current.icNo === icNo
    )

    if (
      fullName &&
      phoneNo &&
      icNo &&
      isNewContact &&
      !CompanyPurchaseCheck.current
    ) {
      const name = watch('Name')
      getExistingContact({
        variables: {
          fullName: typeof name === 'object' ? name.fullName : name,
          phoneNo: watch('MobileNo'),
          icNo: watch('IdentityNo'),
        },
      })
    } else {
      setCheckContactExist(false)
    }
  }

  return (
    <>
      {ImportContactDialog}
      {AddressCopyDialog}
      {DeleteDialog}
      <PurchaserInfoAddressDialog
        open={addressDialog}
        setOpen={setAddressDialog}
        dialogMode={dialogMode}
        addressList={watch('addressList')}
        menu={menu?.data}
        update={update}
        append={append}
        index={menu?.index}
      />
      <form id="submit-form">
        <Container>
          <Card>
            <Typography component="div" display={'flex'} width={'100%'}>
              <Typography component="div" width={'50%'}>
                <Typography
                  color="common.black"
                  component="span"
                  flex="1 1"
                  className="text-desc"
                  variant="inherit"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="CompanyPurchase"
                        color="primary"
                        disabled={
                          mode == 'add' || (mode == 'edit' && !isMainBuyer)
                        }
                        onChange={e => {
                          CompanyPurchaseCheck.current = e.target.checked
                          resetCompanyField()
                          triggerChange(e.target.checked)
                        }}
                        checked={CompanyPurchaseCheck.current}
                      />
                    }
                    label="Company Purchase"
                  />
                </Typography>
              </Typography>
              <Typography component="div" width={'50%'} textAlign={'right'}>
                <Typography
                  component="span"
                  variant="inherit"
                  className="text-desc"
                  color="primary"
                >
                  <DialogButton
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Scan ID <QrCodeScannerTwoTone />
                  </DialogButton>
                </Typography>
              </Typography>
            </Typography>
          </Card>
          {mode != 'add' && salesStatus != SaleStatus.Booked && (
            <Card className="card-container-detail">
              <Box className="left">
                <Typography className="label-f">Nationality</Typography>
                <Typography className="value-f">
                  {contactInfo?.nationality?.name ?? '-'}
                </Typography>
              </Box>

              <Box className="right">
                <Typography className="label-f">
                  {contactInfo?.companyName
                    ? 'Director Name'
                    : 'Name as per NRIC'}
                </Typography>
                <Typography className="value-f">
                  {contactInfo?.fullName}
                </Typography>
              </Box>

              <Box className="left">
                <Typography className="label-f">Email</Typography>
                <Typography className="value-f">
                  {contactInfo?.email ?? '-'}
                </Typography>
              </Box>

              <Box className="right">
                <Typography className="label-f">Identity Type</Typography>
                <Typography className="value-f">
                  {IdentityTypeListing?.find(
                    el => el.ID == contactInfo?.contactExt?.identityType
                  )?.Name ?? '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className="label-f">
                  {!CompanyPurchaseCheck.current
                    ? 'Identity No'
                    : 'Director NRIC'}
                </Typography>
                <Typography className="value-f">
                  {contactInfo?.icNo ?? '-'}
                </Typography>
              </Box>
            </Card>
          )}

          <Card>
            {CompanyPurchaseCheck.current && (
              <Controller
                control={control}
                name="CompanyName"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    disablePortal
                    fullWidth
                    loading={dropdownLoading}
                    freeSolo
                    getOptionLabel={option => {
                      if (option?.companyName) {
                        return option.companyName
                      }
                      return option?.['companyLabel'] || option
                    }}
                    getOptionKey={option => option?.['id']}
                    options={subContactList}
                    onChange={(event, value) => {
                      onChange(value)
                      setContactId(value?.id ?? null)
                      setDetails(value)
                      setValue('addressList', [])
                      value?.addressList?.map(el => {
                        append({
                          ...el,
                          addressId: el.id,
                        })
                      })
                      if (!value) {
                        offsetRef.current = 0
                        setSearchQuery('')
                        if (!searchQuery) {
                          fetchOptions() // Recall api if no search input before clearing value
                        }
                      }
                    }}
                    onInputChange={(e, value) => {
                      if (e) {
                        onChange(value)
                      }
                      if (!value) {
                        //reset list if there is no value
                        setSubContactList([])
                        setSearchQuery('')
                        setHasMore(true)
                      }
                    }}
                    value={value}
                    renderOption={(props, option, state) => {
                      const isLastOption =
                        state.index === subContactList.length - 1

                      if (isLastOption && hasMore && dropdownLoading) {
                        return (
                          <>
                            <li {...props} key={option.id}>
                              {option.companyLabel}
                            </li>
                            <li {...props} ref={inViewRef}>
                              Loading...
                            </li>
                          </>
                        )
                      }

                      return (
                        <li
                          {...props}
                          key={option.id}
                          ref={isLastOption ? inViewRef : null}
                        >
                          {option.companyLabel}
                        </li>
                      )
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Company Name"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        required
                        onChange={e => {
                          setDropdownLoading(true)
                          debouncedSearchQuery(e.target.value)
                        }}
                      />
                    )}
                  />
                )}
              />
            )}

            {CompanyPurchaseCheck.current && (
              <Controller
                control={control}
                name="CompanyReg"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    name="CompanyReg"
                    label="Business Registration No. (BRN)"
                    required
                    value={value}
                    helperText={errors?.CompanyReg?.message}
                    error={errors?.CompanyReg ? true : false}
                  />
                )}
              />
            )}

            {CompanyPurchaseCheck.current && (
              <Controller
                control={control}
                name="DirectorName"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    variant="standard"
                    fullWidth
                    margin="normal"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    name="DirectorName"
                    label="Director Name"
                    required
                    value={value}
                    helperText={errors?.DirectorName?.message}
                    error={errors?.DirectorName ? true : false}
                  />
                )}
              />
            )}

            {!CompanyPurchaseCheck.current && (
              <Controller
                control={control}
                name="Name"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={subContactList}
                    loading={dropdownLoading}
                    freeSolo
                    getOptionLabel={option => {
                      if (option?.fullName) {
                        return option.fullName
                      }
                      return option?.label || option || ''
                    }}
                    getOptionKey={option => option?.['id']}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(event, value) => {
                      onChange(value)
                      setContactId(value?.id ?? null)
                      setDetails(value)
                      setValue('addressList', [])
                      value?.addressList?.map(el => {
                        append({
                          ...el,
                          addressId: el.id,
                        })
                      })
                      setCheckContactExist(false)
                      if (!value) {
                        offsetRef.current = 0
                        setSearchQuery('')
                        if (!searchQuery) {
                          fetchOptions() // Recall api if no search input before clearing value
                        }
                      }
                    }}
                    onBlur={() => {
                      if (checkContactExist) {
                        handleBlurEvent()
                      }
                    }}
                    onInputChange={(e, value, reason) => {
                      if (reason === 'input') {
                        setCheckContactExist(true)
                      }
                      if (e && value) {
                        onChange(value)
                      }
                      if (!value) {
                        //reset list if there is no value
                        setSubContactList([])
                        setSearchQuery('')
                        setHasMore(true)
                      }
                    }}
                    value={value}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Name as per NRIC"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        value={value}
                        helperText={errors?.Name?.message}
                        error={errors?.Name ? true : false}
                        required
                        style={{
                          display:
                            mode == 'add' || salesStatus == SaleStatus.Booked
                              ? 'inline-flex'
                              : 'none',
                        }}
                        onChange={e => {
                          setDropdownLoading(true)
                          debouncedSearchQuery(e.target.value)
                        }}
                      />
                    )}
                    renderOption={(props, option, state) => {
                      const isLastOption =
                        state.index === subContactList.length - 1

                      if (isLastOption && hasMore && dropdownLoading) {
                        return (
                          <>
                            <li {...props} key={option.id}>
                              {option.label}
                            </li>
                            <li {...props} ref={inViewRef}>
                              Loading...
                            </li>
                          </>
                        )
                      }

                      return (
                        <li
                          {...props}
                          key={option.id}
                          ref={isLastOption ? inViewRef : null}
                        >
                          {option.label}
                        </li>
                      )
                    }}
                  />
                )}
              />
            )}

            <Controller
              control={control}
              name="Nationality"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  options={Nationalities}
                  isOptionEqualToValue={(option, value) =>
                    option?.['id'] === value?.['id']
                  }
                  getOptionLabel={option => option?.['name']}
                  fullWidth
                  value={value}
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Nationality"
                      // required
                      variant="standard"
                      margin="normal"
                      value={value}
                      autoComplete="off"
                      style={{
                        display:
                          mode == 'add' || salesStatus == SaleStatus.Booked
                            ? 'inline-flex'
                            : 'none',
                      }}
                    />
                  )}
                />
              )}
            />

            <Box
              display={
                mode == 'add' || salesStatus == SaleStatus.Booked
                  ? 'flex'
                  : 'none'
              }
              width="100%"
            >
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="IdentityType"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      select
                      variant="standard"
                      fullWidth
                      margin="normal"
                      onBlur={onBlur}
                      onChange={event => {
                        onChange(event)
                        const brn = watch('CompanyReg')
                        if (event.target.value === 'BUSINESS_LICENSE' && brn) {
                          setValue('IdentityNo', brn)
                        }
                      }}
                      autoComplete="off"
                      name="IdentityType"
                      label="Identity Type"
                      required
                      value={value}
                      helperText={errors?.IdentityType?.message}
                      error={errors?.IdentityType ? true : false}
                    >
                      {IdentityTypeListing?.map((v, i) => (
                        <MenuItem value={v.ID} key={i}>
                          {v.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                {watch('IdentityType') == 'IDENTITY_CARD' ||
                !watch('IdentityType') ? (
                  <Controller
                    control={control}
                    name="IdentityNo"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NricTextField
                        label={
                          !CompanyPurchaseCheck.current
                            ? 'Identity No'
                            : 'Director NRIC'
                        }
                        name="IdentityNo"
                        onBlur={() => {
                          onBlur()
                          if (checkContactExist) {
                            handleBlurEvent()
                          }
                        }}
                        onChange={e => {
                          onChange(e)
                          setCheckContactExist(true)
                        }}
                        value={value}
                        helperText={errors?.IdentityNo?.message}
                        error={errors?.IdentityNo ? true : false}
                        required={true}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    name="IdentityNo"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        variant="standard"
                        fullWidth
                        margin="normal"
                        onBlur={onBlur}
                        onChange={onChange}
                        autoComplete="off"
                        name="IdentityNo"
                        label={
                          IdentityTypeListing?.find(
                            el => el.ID == watch('IdentityType')
                          )?.Name
                        }
                        required
                        value={value}
                        helperText={errors?.IdentityNo?.message}
                        error={errors?.IdentityNo ? true : false}
                      />
                    )}
                  />
                )}
              </Box>
            </Box>
            <Box display="flex" width="100%">
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Salutation"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={Salutations}
                      isOptionEqualToValue={(option, value) =>
                        option?.['id'] === value?.['id']
                      }
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      value={value}
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Salutation"
                          // required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Race"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={Races}
                      isOptionEqualToValue={(option, value) =>
                        option?.['id'] === value?.['id']
                      }
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Race"
                          // required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          // helperText={errors?.Race?.message}
                          // error={errors?.Race ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box display="flex" width="100%">
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="MaritalStatus"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Autocomplete
                      options={MaritalStatus}
                      getOptionLabel={option => option?.['name']}
                      fullWidth
                      onChange={(event, value) => {
                        onChange(value)
                      }}
                      value={value}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Marital Status"
                          // required
                          variant="standard"
                          margin="normal"
                          value={value}
                          autoComplete="off"
                          // helperText={errors?.MaritalStatus?.message}
                          // error={errors?.MaritalStatus ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box pr="6px" width="50%">
                <Controller
                  control={control}
                  name="Gender"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      select
                      variant="standard"
                      fullWidth
                      margin="normal"
                      onBlur={onBlur}
                      onChange={onChange}
                      autoComplete="off"
                      name="Gender"
                      label="Gender"
                      // required
                      value={value}
                      // helperText={errors?.Gender?.message}
                      // error={errors?.Gender ? true : false}
                    >
                      {Genders &&
                        Object.keys(Genders)?.map((el, i) => (
                          <MenuItem value={el} key={i}>
                            {Genders[el]}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Box>
            </Box>
            <Controller
              control={control}
              name="CustomerOrigin"
              render={({ field: { onChange, onBlur, value } }) => (
                <Autocomplete
                  fullWidth
                  options={customerOriginList}
                  isOptionEqualToValue={(option, value) =>
                    option?.['id'] === value?.['id']
                  }
                  getOptionLabel={option => option?.['name']}
                  onChange={(event, newValue) => {
                    onChange(newValue)
                    assignTin()
                  }}
                  getOptionDisabled={option => {
                    if (
                      option.id === 'GOVERNMENT_STAFF' &&
                      watch('foreignPurchase') == true
                    ) {
                      return true
                    }
                    return false
                  }}
                  value={value}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Customer Origin"
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                      value={value}
                      helperText={errors?.CustomerOrigin?.message}
                      error={errors?.CustomerOrigin ? true : false}
                      required
                    />
                  )}
                />
              )}
            />
            {/* <Controller
              control={control}
              name="CustomerOrigin"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  select
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={value => {
                    onChange(value)
                    assignTin()
                  }}
                  autoComplete="off"
                  name="CustomerOrigin"
                  label="Customer Origin"
                  required
                  value={value}
                  helperText={errors?.CustomerOrigin?.message}
                  error={errors?.CustomerOrigin ? true : false}
                >
                  {CustomerOriginListing?.map(v => (
                    <MenuItem value={v.ID}>{v.Name}</MenuItem>
                  ))}
                </TextField>
              )}
            /> */}
            <Box width="100%" display="flex">
              <Box width="50%" pr="6px">
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="bumiputera"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          color="primary"
                          onChange={e => onChange(e.target.checked)}
                          checked={value}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  }
                  label="Bumiputera"
                />
              </Box>

              <Box width="50%" pr="6px">
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="vip"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                          color="primary"
                          onChange={e => onChange(e.target.checked)}
                          checked={value}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  }
                  label="VIP"
                />
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="foreignPurchase"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      color="primary"
                      onChange={(event, value) => {
                        onChange(event.target.checked)
                        assignTin()
                      }}
                      checked={value}
                      onBlur={onBlur}
                      disabled={
                        watch('CustomerOrigin')?.id === 'GOVERNMENT_STAFF'
                          ? true
                          : false
                      }
                    />
                  )}
                />
              }
              label="Foreign Purchase"
            />

            <Controller
              control={control}
              name="Email"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  // name="Email"
                  label={disableLabelAutocomplete('Email')}
                  required
                  value={value}
                  helperText={errors?.Email?.message}
                  error={errors?.Email ? true : false}
                  // style={{
                  //   display:
                  //     salesStatus == SaleStatus.Booked ? 'inline-flex' : 'none',
                  // }}
                />
              )}
            />

            <Box width="100%" display="flex">
              <Box width="50%" pr="8px">
                <Controller
                  control={control}
                  name="HomeTelNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomPhoneInput
                      onChange={onChange}
                      value={value}
                      label="Home Tel No."
                      error={!!errors?.HomeTelNo}
                      errorText={errors?.HomeTelNo?.message}
                    />
                  )}
                />
              </Box>

              <Box width="50%" pr="8px">
                <Controller
                  control={control}
                  name="MobileNo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CustomPhoneInput
                      onBlur={() => {
                        onBlur()
                        if (checkContactExist) {
                          handleBlurEvent()
                        }
                      }}
                      onChange={e => {
                        onChange(e)
                        setCheckContactExist(true)
                      }}
                      value={value}
                      label="Mobile No."
                      required
                      error={!!errors?.MobileNo}
                      errorText={errors?.MobileNo?.message}
                    />
                  )}
                />
              </Box>
            </Box>

            <Controller
              control={control}
              name="OfficeTelNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomPhoneInput
                  onChange={onChange}
                  value={value}
                  label="Office Tel No."
                  error={!!errors?.OfficeTelNo}
                  errorText={errors?.OfficeTelNo?.message}
                />
              )}
            />

            <Box width="100%">
              <FormControlLabel
                control={
                  <Checkbox
                    name="SubscribeMarketing"
                    color="primary"
                    onChange={e => toggleParent(e.target.checked)}
                    checked={parentChecked}
                    indeterminate={parentIndeterminate}
                  />
                }
                label="Subscribe Marketing Material"
              />
            </Box>

            {MarketingMaterials.map(el => (
              <Box width="100%" marginLeft="2%" key={el.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={e => toggleChild(el.id)}
                      checked={checkedItems[el.id] || false}
                    />
                  }
                  label={el.name}
                />
              </Box>
            ))}

            <Box>
              <Typography
                className="text-xlTitle"
                color="common.black"
                component="div"
                flex="1 1"
                variant="inherit"
              >
                Mailing Method *
              </Typography>
              {/* <FormControl fullWidth>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={radioState}
                >
                  <FormControlLabel
                    value="Email"
                    control={<Radio />}
                    onChange={() => setRadioState('Email')}
                    label={'Email'}
                  />

                  <FormControlLabel
                    value="Letter"
                    control={<Radio />}
                    onChange={() => setRadioState('Letter')}
                    label={'Letter'}
                  />
                </RadioGroup>
              </FormControl> */}
              <FormControl fullWidth>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={emailChecked}
                        onChange={() => setEmailChecked(!emailChecked)}
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={letterChecked}
                        onChange={() => setLetterChecked(!letterChecked)}
                      />
                    }
                    label="Letter"
                  />
                </FormControl>
              </FormControl>
            </Box>

            <VoiceTextField
              mounted={true}
              label="Remark"
              name="Remark"
              required={false}
              setValue={setValue}
              record={record}
              setRecord={setRecord}
              controllerProps={{
                error: !!errors?.Remark,
                helperText: errors?.Remark?.message,
                control: control,
                autoComplete: 'off',
              }}
              control={control}
            />
            <Controller
              control={control}
              name="Tin"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="Tin"
                  label="TIN"
                  // required
                  value={value}
                  InputProps={{
                    readOnly:
                      watch('CustomerOrigin.id') === 'GOVERNMENT_STAFF' ||
                      watch('foreignPurchase')
                        ? true
                        : false,
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="SstNo"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  margin="normal"
                  onBlur={onBlur}
                  onChange={onChange}
                  autoComplete="off"
                  name="SstNo"
                  label="SST Registration No"
                  // required
                  value={value}
                  // helperText={errors?.Email?.message}
                  // error={errors?.Email ? true : false}
                  // style={{
                  //   display:
                  //     salesStatus == SaleStatus.Booked ? 'inline-flex' : 'none',
                  // }}
                />
              )}
            />
          </Card>
          {/* <Card> */}
          <div className="address-card-list">
            <CardListHeader
              title={
                <Typography
                  className="text-xsTitle"
                  color="#FF9800"
                  component="span"
                  width="100%"
                  flex="1 1"
                  variant="inherit"
                >
                  Address Listing
                </Typography>
              }
              action={
                <RowFlex crossAxisAlignment="center">
                  {(contactInfo === null ||
                    (!isMainPurchaser.current &&
                      !CompanyPurchaseCheck.current)) && (
                    <IconButton
                      aria-label="Copy Main Purchaser's Address"
                      style={{
                        padding: '0px',
                      }}
                      onClick={() => {
                        setCopyAddressDialog(true)
                      }}
                    >
                      <IconX icon={DevicesFoldIcon} size={24} color="#FF9800" />
                    </IconButton>
                  )}
                  <SizedBox width={'5px'} />
                  <IconButton
                    aria-label="Addbox"
                    style={{
                      padding: '0px',
                    }}
                    onClick={() => {
                      if (fields?.length < 4) {
                        setAddressDialog(true)
                        setDialogMode('Add')
                        handleClick(undefined, '', 0, null)
                      } else {
                        setSnackBar(SystemMsgs.addressLimit())
                      }
                    }}
                  >
                    <AddBox color="primary" />
                  </IconButton>
                </RowFlex>
              }
            >
              {fields.length === 0 ? (
                <EmptyState
                  title="No Address Added"
                  subTitle="Click add button to add address."
                />
              ) : (
                <>{addressListingItems(fields)}</>
              )}
            </CardListHeader>
          </div>
          {/* </Card> */}

          <Menu
            id="menu-list"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAddressDialog(true)
                setDialogMode('Edit')
              }}
            >
              <span className="">Edit</span>{' '}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeleteDialog(true)
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </Menu>
          <Footer
            buttons={[
              {
                children: 'Save',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
              },
            ]}
          />
        </Container>
      </form>
    </>
  )
}
