import { lazy } from 'react'
import { RouteObject } from 'react-router'
import DevTestingListing from './DevTestingListing'

const CustomTableShowcase = lazy(() => import('./CustomTableShowcase'))

const DevTestingRoutes: RouteObject[] = [
  {
    path: '',
    element: <DevTestingListing />,
  },
  {
    path: 'custom-table',
    element: <CustomTableShowcase />,
  },
]

export default DevTestingRoutes
