export const SystemMsgs = {
  name: () => {
    return 'Name is required'
  },
  userName: () => {
    return 'Login Username is required'
  },
  department: () => {
    return 'Department is required'
  },
  designation: () => {
    return 'Designation is required'
  },
  code: () => {
    return 'Code is required'
  },
  description: () => {
    return 'Description is required'
  },
  contactNo: () => {
    return 'Contact No is required'
  },
  mobileNo: () => {
    return 'Mobile No is required'
  },
  phoneNo: () => {
    return 'Phone No is required'
  },
  officeTelNo: () => {
    return 'Office Tel No is required'
  },
  email: () => {
    return 'Email is required'
  },
  password: () => {
    return 'Password is required'
  },
  date: () => {
    return 'Date is required'
  },
  financierName: () => {
    return 'Financier Name is required'
  },
  requestedBy: () => {
    return 'Requested by is required'
  },
  contactPerson: () => {
    return 'Contact Person is required'
  },
  addressType: () => {
    return 'Address Type is required'
  },
  creditTerm: () => {
    return 'Credit Term is required'
  },
  // mobileNo: () => {
  //   return 'Mobile No is required'
  // },
  specialInstruction: () => {
    return 'Special instruction is required'
  },
  createNewRecord: () => {
    return 'Record added successfully.'
  },
  cloneNewRecord: () => {
    return 'Record cloned successfully.'
  },
  generateRecord: () => {
    return 'Record generated successfully.'
  },
  updateRecord: () => {
    return 'Record updated successfully.'
  },
  deleteRecord: () => {
    return 'Record deleted successfully.'
  },
  cancelRecord: () => {
    return 'Record Cancelled successfully.'
  },
  assignRecord: () => {
    return 'Record Assigned successfully.'
  },
  unassignRecord: () => {
    return 'Record Unassigned successfully.'
  },
  activeRecord: () => {
    return 'Record activated successfully'
  },
  deactiveRecord: () => {
    return 'Record deactivated successfully'
  },
  //Standard Address fields validation
  address: () => {
    return 'Address is required'
  },
  state: () => {
    return 'State is required'
  },
  city: () => {
    return 'City is required'
  },
  postCode: () => {
    return 'Postcode is required'
  },
  country: () => {
    return 'Country is required'
  },
  //company fields validation
  companyName: () => {
    return 'Company Name is required'
  },
  regNo: () => {
    return 'Registration No. is required'
  },
  title: () => {
    return 'Title is required'
  },
  predicate: () => {
    return 'Predicate is required'
  },
  role: () => {
    return 'Role is required'
  },
  remark: () => {
    return 'Remark is required'
  },
  accountCode: () => {
    return 'Bank Code is required'
  },
  accountType: () => {
    return 'Account Type is required'
  },
  accountNo: () => {
    return 'Account No. is required'
  },
  currency: () => {
    return 'Currency is required'
  },
  swiftCode: () => {
    return 'Swift Code is required'
  },
  effectiveDate: () => {
    return 'Effective Date is required'
  },
  contractorType: () => {
    return 'Contractor Type is required'
  },
  planName: () => {
    return 'Plan Name is required'
  },
  paymentMethod: () => {
    return 'Payment Method is required'
  },
  financierCode: () => {
    return 'Financier Code is required'
  },
  uom: () => {
    return 'UOM is required'
  },
  tenure: () => {
    return 'Tenure is required'
  },
  landtype: () => {
    return 'Land Type is required'
  },
  projecttype: () => {
    return 'Project Type is required'
  },
  company: () => {
    return 'Company is required'
  },
  price: () => {
    return 'Price cannot be zero'
  },
  dateSelection: () => {
    return 'Select a date'
  },
  interestRate: () => {
    return 'Select a date'
  },
  bankName: () => {
    return 'Bank Name is required'
  },
  bankCode: () => {
    return 'Bank Code is required'
  },
  amountValue: () => {
    return 'Amount Value is required'
  },
  numberOfPayment: () => {
    return 'Number of Payment is required'
  },
  gracePeriod: () => {
    return 'Payment Grace Period is required'
  },
  roundingPrecision: () => {
    return 'Progress Billing Rounding Precision is required'
  },
  roundingMode: () => {
    return 'Progress Billing Rounding Mode is required'
  },
  snpPeriod: () => {
    return 'Signing S&P Period (Days) is required'
  },
  setDefault: () => {
    return 'Marked as default successfully'
  },
  removeDefault: () => {
    return 'Removed as default successfully'
  },
  approveRecord: () => {
    return 'Record approved successfully'
  },
  rejectRecord: () => {
    return 'Record rejected successfully'
  },
  postRecord: () => {
    return 'Record posted successfully'
  },
  voidRecord: () => {
    return 'Record voided successfully'
  },
  reverseRecord: () => {
    return 'Record reversed successfully'
  },
  updateSequence: () => {
    return 'Record sequence updated succesfully'
  },
  checkAmount: amount => {
    return `Payment Amount cannot more than ${amount}`
  },
  duplicatePaymentPlan: () => {
    return `Record duplicated successfully`
  },
  duplicateNumberStructure: () => {
    return `Record copied successfully`
  },
  noPaymentPlanDetail: () => {
    return `Required at least 1 Payment Plan Detail`
  },

  addressLimit: () => {
    return `All address type already have been selected.`
  },

  correspondenceAddressDelete: () => {
    return `Cannot delete Correspondence Address. Please asign correspondence address to different address before deleting this address`
  },

  correspondenceAddress: () => {
    return `At least 1 primary address is required`
  },

  multipleAddress: () => {
    return `At least 1 address input is required `
  },
  sendEmail: () => {
    return `An email with a link to reset your password has been sent to your email address. Please check your mailbox.`
  },

  architectureCertUnit: () => {
    return `At least 1 unit is required`
  },
  outstandingKeyColletion: () => {
    return `Please clear out the outstanding first before completing the key collection appointment`
  },
  signedRecord: () => {
    return `Sales signed successfully.`
  },
  generatedAndSignedRecord: () => {
    return `Progress billing generated and sales signed successfully.`
  },
}
