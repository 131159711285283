import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  carParkForm4Props,
  specialLevelProps,
  specialUnitProps,
  unitSetupForm4Props,
} from '../UnitInterface'
import { unitSetupForm1Props } from './SetupForm1'

interface unitSetupForm2State {
  floorList: number[]
  floorByRange: boolean
  floorNumber: string
  floorFrom: number
  floorTo: number
  specialLevelList: specialLevelProps[]
}

interface unitSetupForm3State {
  unitList: string[]
  unitByRange: boolean
  unitNumber: string
  unitFrom: number
  unitTo: number
  unitDigit: number
  specialUnitList: specialUnitProps[]
}

interface SetupFormState {
  form1Info: unitSetupForm1Props
  form2Info: unitSetupForm2State
  form3Info: unitSetupForm3State
  form4Info: unitSetupForm4Props | carParkForm4Props
}

export interface SetupState {
  setupInfo: SetupFormState
  currentPage: '1' | '2' | '3' | '4'
  isCarpark: boolean
}

interface SetupContextType {
  SetupState: SetupState
  setSetupState: React.Dispatch<React.SetStateAction<SetupState>>
}

const SetupContext = createContext<SetupContextType | undefined>(undefined)

export const SetupProvider = ({ children }: { children: ReactNode }) => {
  const [SetupState, setSetupState] = useState<SetupState>({
    setupInfo: null,
    currentPage: '1',
    isCarpark: false,
  })

  const contextValue = useMemo(
    () => ({ SetupState, setSetupState }),
    [SetupState]
  )

  return (
    <SetupContext.Provider value={contextValue}>
      {children}
    </SetupContext.Provider>
  )
}

export const useSetupState = () => {
  const context = useContext(SetupContext)
  if (context === undefined) {
    throw new Error('useSetupState must be used within an SetupProvider')
  }
  return context
}
