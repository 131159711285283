import React, { createContext, ReactNode, useContext, useState } from 'react'

// interface tenantEInvoicesSummaryState {
//   count: number
//   title: string
//   availableModule: string[]
//   availableTrxType: string[]
//   data: any
// }
// interface tenantEInvoicesState {
//   newRecord: tenantEInvoicesSummaryState
//   pendingSend: tenantEInvoicesSummaryState
//   failedSend: tenantEInvoicesSummaryState
//   failedValidate: tenantEInvoicesSummaryState
//   successValidate: tenantEInvoicesSummaryState
//   requestReject: tenantEInvoicesSummaryState
//   requestNCN: tenantEInvoicesSummaryState
//   requestCN: tenantEInvoicesSummaryState
//   completeTransaction: tenantEInvoicesSummaryState
// }



// Define the EinvoiceState interface
interface EinvoiceState {
  tenantEinvoices: { status: string; data: any }
  consolidateEinvoices: { status: string; data: any }
  billItemSettings: { status: string; data: any }
  companySettings: { status: string; data: any }
}

// Define the context type
interface EinvoiceContextType {
  einvoiceState: EinvoiceState
  setEinvoiceState: React.Dispatch<React.SetStateAction<EinvoiceState>>
}

// Create the context
const EinvoiceContext = createContext<EinvoiceContextType | undefined>(
  undefined
)

// Create the provider component
export const EinvoiceProvider = ({ children }: { children: ReactNode }) => {
  const [einvoiceState, setEinvoiceState] = useState<EinvoiceState>({
    tenantEinvoices: { status: 'loading', data: null },
    consolidateEinvoices: { status: 'loading', data: null },
    billItemSettings: { status: 'loading', data: null },
    companySettings: { status: 'loading', data: null },
  })

  return (
    <EinvoiceContext.Provider value={{ einvoiceState, setEinvoiceState }}>
      {children}
    </EinvoiceContext.Provider>
  )
}

// Custom hook to use the EinvoiceContext
export const useEinvoiceContext = () => {
  const context = useContext(EinvoiceContext)
  if (context === undefined) {
    throw new Error(
      'useEinvoiceContext must be used within an EinvoiceProvider'
    )
  }
  return context
}
