import { lazy } from 'react'

const TenantEInvoicesSubmenu = lazy(() => import('./TenantEInvoicesSubmenu'))
const TenantEInvoicesDetail = lazy(() => import('./TenantEInvoicesDetail'))
const TenantEInvoicesTenantSummary = lazy(
  () => import('./TenantEInvoicesTenantSummary')
)

const TenantEinvoicesRoutes = [
  {
    path: 'tenant-einvoices',
    children: [
      {
        path: '',
        element: <TenantEInvoicesSubmenu />,
      },
      {
        path: 'detail',
        children: [
          {
            path: ':category',
            element: <TenantEInvoicesDetail />,
          },
          {
            path: ':category/summary',
            element: <TenantEInvoicesTenantSummary />,
          },
        ],
      },
    ],
  },
]

export default TenantEinvoicesRoutes
