import { lazy } from 'react'
import { RouteObject } from 'react-router'
const PAprogressBillingListing = lazy(
  () => import('./PAprogressBillingListing')
)
const PAprogressBillingDetail = lazy(() => import('./PAprogressBillingDetail'))

const PAprogressBillingRoute: RouteObject[] = [
  {
    path: 'progress-billing',
    children: [
      {
        path: '',
        element: <PAprogressBillingListing isPA={true} />,
      },
      {
        path: ':id/view',
        element: <PAprogressBillingDetail isPA={true} />,
      },
    ],
  },
  {
    path: 'sale-product',
    children: [
      {
        path: '',
        element: (
          <PAprogressBillingListing isPA={true} addOnRef={'sale_product'} />
        ),
      },
      {
        path: ':id/view',
        element: <PAprogressBillingDetail isPA={true} />,
      },
    ],
  },
  {
    path: 'car-park',
    children: [
      {
        path: '',
        element: (
          <PAprogressBillingListing isPA={true} addOnRef={'sale_subproduct'} />
        ),
      },
      {
        path: ':id/view',
        element: <PAprogressBillingDetail isPA={true} />,
      },
    ],
  },
  {
    path: 'sales-package',
    children: [
      {
        path: '',
        element: (
          <PAprogressBillingListing isPA={true} addOnRef={'sale_package'} />
        ),
      },
      {
        path: ':id/view',
        element: <PAprogressBillingDetail isPA={true} />,
      },
    ],
  },
]

export default PAprogressBillingRoute
